import React from "react";
import { Paper, Typography } from "@material-ui/core";
import "./ContactPage.component.sass";

export function ContactPageComponent() {
  return (
    <Paper className={"contact-page-container"}>
      <span>Contact</span>
      <div className={"content"}>
        <div className={"item"}>
          <ul>
            <li><Typography component="h2">Brainport Development</Typography></li>
            <li>Gebouw TQ 5 – Strijp T</li>
            <li>Achtseweg Zuid 159H</li>
            <li>5651 GW Eindhoven</li>
            <li>
              Website: <a target="_blank" rel="noopener noreferrer" href="http://www.brainporteindhoven.com/talent">http://www.brainporteindhoven.com/talent</a>
            </li>
            <li>Contactpersoon:</li>
            <ul>
              <li className="active">Nicole Zwetsloot</li>
              <li>
                Telefoon: <a href="tel:+31629495834">+31 629495834</a>
              </li>
              <li>
                Email: <a href="mailto:n.zwetsloot@brainportdevelopment.nl">n.zwetsloot@brainportdevelopment.nl</a>
              </li>
            </ul>
          </ul>
        </div>
        <div className={"item"}>
        <ul>
          <li><Typography component="h2">Holland Expat Center South</Typography></li>
          <li>Vestdijk 27A</li>
          <li>5611 CA Eindhoven</li>
          <li>Website: <a target="_blank" rel="noopener noreferrer" href="www.hollandexpatcenter.com ">www.hollandexpatcenter.com</a></li>
          <li>Contactpersonen:</li>
          <ul>
            <li className="active">Iris van Daal</li>
            <li>
              Telefoon: <a href="tel:+31655392113">+31 655392113</a>
            </li>
            <li>
              Email: <a href="mailto:i.van.daal@hollandexpatcenter.com">i.van.daal@hollandexpatcenter.com</a>
            </li>
            <li className="active">Janneke Willems</li>
            <li>
              Telefoon: <a href="tel:+31611343539">+31 611343539</a>
            </li>
            <li>
              Email: <a href="mailto:n.j.willems@hollandexpatcenter.com">j.willems@hollandexpatcenter.com</a>
            </li>
            <li className="active">Ed Heerschap</li>
            <li>
              Telefoon: <a href="tel:+31651304757">+31 651304757</a>
            </li>
            <li>
              Email: <a href="mailto:e.heerschap@hollandexpatcenter.com">e.heerschap@hollandexpatcenter.com</a>
            </li>
          </ul>
          </ul>
        </div>
      </div>
    </Paper>
  );
}
