import React, { useState } from "react";
import "./DataList.component.sass";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {
  Breadcrumbs,
  Link,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
} from "@material-ui/core";
import { CompaniesComponent } from "../companies/Companies.component";
import { QuestionnairesComponent } from "../questionnaires/Questionnaires.component";
import { Filter } from "../filter";
import { Error as ErrorComponent } from "../../../../../common/error/Error.component";
import { API_URL } from "../../../../../../lib/constants";
import fileDownload from "js-file-download";

export function DataListComponent() {
  const emptyFilter = {
    companies: [],
    questionnaires: [],
    users: [],
  };

  const [activeStepState, setActiveStepState] = useState<number>(0);
  const [filterState, setFilterState] = useState<Filter>(emptyFilter);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);

  const steps = [
    "Selecteer de bedrijven",
    "Selecteer de vragenlijsten",
    // "Selecteer gebruikers (optioneel)",
  ];

  const downloadData = async () => {
    try {
      const apiResponse = await fetch(`${API_URL}/data/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(filterState),
      });

      const contentDispositionHeader = apiResponse.headers.get(
        "content-disposition"
      );

      if (contentDispositionHeader) {
        const regexMatches = /^attachment; filename="(.*)"$/gm.exec(
          contentDispositionHeader
        );

        fileDownload(
          await apiResponse.blob(),
          regexMatches && regexMatches.length > 1
            ? regexMatches[1]
            : "download.csv"
        );

        setFilterState(emptyFilter);
        setActiveStepState(0);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getStepContent = () => {
    switch (activeStepState) {
      case 0:
        return (
          <CompaniesComponent
            filter={filterState}
            setFilter={setFilterState}
            setHasError={setHasErrorState}
          />
        );
      case 1:
        return (
          <QuestionnairesComponent
            filter={filterState}
            setFilter={setFilterState}
            setHasError={setHasErrorState}
          />
        );
      // case 2:
      //   return (
      //     <UsersComponent
      //       filter={filterState}
      //       setFilter={setFilterState}
      //       setHasError={setHasErrorState}
      //     />
      //   );
      default:
        throw Error("There is no step with the selected index");
    }
  };

  const isNextDisabled = () => {
    switch (activeStepState) {
      case 0:
        return !filterState.companies.length;
      case 1:
        return !filterState.questionnaires.length;
      default:
        return false;
    }
  };

  const handleNext = async () => {
    if (activeStepState === steps.length - 1) {
      await downloadData();
    } else {
      setActiveStepState((prevState) => prevState + 1);
    }
  };

  const handleBack = () => {
    setActiveStepState((prevState) => prevState - 1);
  };

  if (hasErrorState) {
    return <ErrorComponent />;
  }

  return (
    <div className={"data-list-container"}>
      <div className={"data-list-container__header"}>
        <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
          <Link className={"active"} color="textPrimary" aria-current="page">
            <SaveAltIcon />
            Data
          </Link>
        </Breadcrumbs>
      </div>
      <Stepper activeStep={activeStepState} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper className={"data-list-container__paper"} elevation={0}>
        <div className={"data-list-container__paper__content"}>
          {getStepContent()}
        </div>
        <div className={"data-list-container__paper__actions"}>
          <Button disabled={activeStepState === 0} onClick={handleBack}>
            Vorige
          </Button>
          <Button
            disabled={isNextDisabled()}
            variant="contained"
            color="secondary"
            onClick={handleNext}
          >
            {activeStepState === steps.length - 1
              ? "Download data"
              : "Volgende"}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
