import React from "react";
import "./Error.component.sass";
import { Button } from "@material-ui/core";

export interface ErrorProps {
  code?: string;
  message?: string;
  buttonText?: string;
}

export function Error({ code, message, buttonText }: ErrorProps) {
  const reload = () => window.location.reload();

  return (
    <div className={"error-container"}>
      <h2 className={"error-container__title"}>Er is een fout opgetreden</h2>
      {!!code && <h3 className={"error-container__code"}>Foutcode: {code}</h3>}
      {!!message && <p className={"error-container__message"}>{message}</p>}
      <Button
        variant="contained"
        color="primary"
        className={"error-container__button"}
        onClick={reload}
      >
        {!!buttonText ? buttonText : "Probeer overnieuw"}
      </Button>
    </div>
  );
}
