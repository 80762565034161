import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Link, Tabs, Tab } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Questionnaire } from "../../../../../models/questionnaire/questionnaire";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import "./QuestionnaireDetailPage.component.sass";
import { StepsComponent } from "./steps/Steps.component";
import { DetailsComponent } from "./details/Details.component";
import { TabPanelComponent } from "./tab-panel/TabPanel.component";
import { CategoriesComponent } from "./categories/Categories.component";
import { useApi } from "../../../../common/providers/Api.provider";

export function QuestionnaireDetailPageComponent() {
  const api = useApi();
  const { questionnaireId } = useParams();
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [
    isQuestionnaireLoadingState,
    setIsQuestionnaireLoadingState,
  ] = useState<boolean>(false);
  const [questionnaireState, setQuestionnaireState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [activeTabState, setActiveTabState] = useState<number>(0);

  const getQuestionnaire = async (questionnaireId: number) => {
    try {
      setIsQuestionnaireLoadingState(true);
      const response = await api.questionnairesById(questionnaireId);

      setQuestionnaireState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsQuestionnaireLoadingState(false);
    }
  };

  const onSaveChanges = (questionnaire: Questionnaire) => {
    setQuestionnaireState(questionnaire);
  };

  useEffect(() => {
    if (!!questionnaireId) {
      const nummericQuestionnaireId = Number(questionnaireId);
      getQuestionnaire(nummericQuestionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  if (isQuestionnaireLoadingState || !questionnaireState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <div className={"questionnaire-detail-page-container page"}>
        <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
          <Link color="inherit" href="/questionnaires">
            <AssignmentIcon />
            Vragenlijsten
          </Link>
          <Link className={"active"} color="textPrimary" aria-current="page">
            {questionnaireState.name}
          </Link>
        </Breadcrumbs>
        <div className={"questionnaire-detail-page-container__details"}>
          <Tabs
            value={activeTabState}
            onChange={(event: React.ChangeEvent<{}>, newIndex: number) => {
              setActiveTabState(newIndex);
            }}
          >
            <Tab label="Details" />
            <Tab label="Categorieën" />
            <Tab label="Stappen" />
          </Tabs>
          <TabPanelComponent index={0} activeIndex={activeTabState}>
            <DetailsComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
              onSaveChanges={onSaveChanges}
            />
          </TabPanelComponent>
          <TabPanelComponent index={1} activeIndex={activeTabState}>
            <CategoriesComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
          <TabPanelComponent index={2} activeIndex={activeTabState}>
            <StepsComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
        </div>
      </div>
    </>
  );
}
