import React from "react";
import { ResponsiveRadar } from "@nivo/radar";
import { RadarGraphEntry } from "./radarGraphEntry";

export interface RadarGraphProps {
  data: RadarGraphEntry[];
  keys: string[];
  indexedBy: string;
  maxValue: number;
}

export function RadarGraph({
  data,
  keys,
  indexedBy,
  maxValue,
}: RadarGraphProps) {
  return (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy={indexedBy}
      maxValue={maxValue}
      margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
      curve="cardinalClosed"
      borderWidth={2}
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={36}
      enableDots={true}
      dotSize={10}
      dotColor="#ffffff"
      dotBorderWidth={2}
      enableDotLabel={true}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={["#f44336", "#232323"]}
      fillOpacity={0.25}
      blendMode="multiply"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateX: -50,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
}
