import {QuestionType} from '../models/question/questionType';

export function convertQuestionTypeToText(questionType: QuestionType): string {
  switch (questionType) {
    case QuestionType.LIKERT_SCALE:
      return 'Likert schaal';
    case QuestionType.OPEN:
      return 'Open vraag';
    case QuestionType.SELECT:
      return 'Selectie';
    case QuestionType.POLAR:
      return 'Ja / nee';
    default:
      throw Error('Question type not found');
  }
};