import React, { useEffect } from "react";
import { Card, CardContent, Typography, TextField } from "@material-ui/core";
import "./StepTwoPage.component.sass";
import { Company } from "../../../../models/company/company";
import { StepTwoState, validateStepTwoState } from "./StepTwoPageState";

export interface StepTwoPageComponentProps {
  setStepTwoState: React.Dispatch<React.SetStateAction<StepTwoState>>;

  existingCompany: Company | null;

  branch: string;
  postalCode: string;
  city: string;

  showValidationState: boolean;

  kvkNumber: string;
  kvkNumberError?: string;

  name: string;
  nameError?: string;

  phoneNumber: string;
  phoneNumberError?: string;

  email: string;
  emailError?: string;
}

export function StepTwoPageComponent({
  setStepTwoState,
  existingCompany,
  branch,
  postalCode,
  city,
  showValidationState,
  kvkNumber,
  kvkNumberError,
  name,
  nameError,
  phoneNumber,
  phoneNumberError,
  email,
  emailError,
}: StepTwoPageComponentProps) {
  const renderDescription = () => {
    if (existingCompany) {
      return (
        <Typography>
          Het bedrijf is binnen ons platform gevonden. Er hoeft geen informatie
          ingevuld te worden.
        </Typography>
      );
    }

    return (
      <Typography>
        We hebben nog enige informatie nodig om het bedrijf te kunnen aanmelden
        binnen ons platform.
      </Typography>
    );
  };

  useEffect(() => {
    if (existingCompany) {
      setStepTwoState(prevState => validateStepTwoState({
        ...prevState,
        kvkNumber: existingCompany.kvkNumber,
        name: existingCompany.name,
        email: existingCompany.email,
        phoneNumber: existingCompany.phoneNumber,
        postalCode: existingCompany.postalCode,
        city: existingCompany.city,
        branch: existingCompany.branch
      }));
    } else {
      setStepTwoState(prevState => validateStepTwoState({
        ...prevState,
        postalCode,
        city,
        branch
       }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"step-two-container"}>
      <Card className={"card"}>
        <CardContent>
          <Typography variant="h1">
            Stap 2. Aanvullen van bedrijfsinformatie
          </Typography>
          {renderDescription()}
          <form noValidate autoComplete="off">
          <TextField
              required
              fullWidth
              label="Kvk Nummer"
              variant="outlined"
              className={"form-control"}
              type="text"
              disabled={!!existingCompany}
              value={kvkNumber}
              onChange={(event) => {
                if (event.target.value.length <= 8) {
                event.persist();
                  setStepTwoState(prevState => validateStepTwoState({
                    ...prevState,
                    kvkNumber: event.target.value,
                  }));
                }
              }}
              error={!!kvkNumberError && showValidationState}
              helperText={!!kvkNumberError && showValidationState ? kvkNumberError : undefined}
            />
            <TextField
              required
              fullWidth
              label="Naam"
              variant="outlined"
              className={"form-control"}
              type="text"
              disabled={!!existingCompany}
              value={name}
              onChange={(event) => {
                event.persist();
                setStepTwoState(prevState => validateStepTwoState({
                  ...prevState,
                  name: event.target.value,
                }));
              }}
              error={!!nameError && showValidationState}
              helperText={!!nameError && showValidationState ? nameError : undefined}
            />
            <TextField
              label="Telefoonnummer"
              required
              variant="outlined"
              className={"form-control"}
              disabled={!!existingCompany}
              type="tel"
              fullWidth
              value={phoneNumber}
              onChange={(event) => {
                event.persist();
                setStepTwoState(prevState => validateStepTwoState({
                  ...prevState,
                  phoneNumber: event.target.value,
                }));
              }}
              error={!!phoneNumberError && showValidationState}
              helperText={!!phoneNumberError && showValidationState ? phoneNumberError : undefined}
            />
            <TextField
              label="Email"
              required
              variant="outlined"
              className={"form-control"}
              disabled={!!existingCompany}
              type="email"
              fullWidth
              value={email}
              onChange={(event) => {
                event.persist();
                setStepTwoState(prevState => validateStepTwoState({
                  ...prevState,
                  email: event.target.value,
                }));
              }}
              error={!!emailError && showValidationState}
              helperText={!!emailError && showValidationState ? emailError : undefined}
            />
            <TextField
              required
              fullWidth
              disabled 
              label="Postcode"
              variant="outlined"
              className={"form-control"}
              type="text"
              value={postalCode}
            />
            <TextField
              required
              fullWidth
              disabled 
              label="Plaats"
              variant="outlined"
              className={"form-control"}
              type="text"
              value={city}
            />
            <TextField
              required
              fullWidth
              disabled 
              label="Branch"
              variant="outlined"
              className={"form-control"}
              type="text"
              value={branch}
            />
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
