import "./SideMenu.component.sass";
import React, { MouseEventHandler, useState } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink, useHistory } from "react-router-dom";
import { useUser } from "../../common/providers/User.provider";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PeopleIcon from "@material-ui/icons/People";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { UserRole } from "../../../models/user/userRole";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { convertUserRoleToText } from "../../../lib/convertUserRoleToText";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import HomeIcon from "@material-ui/icons/Home";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { UserChangePasswordDialogComponent } from "../pages/user/dialog/user-change-password-dialog/UserChangePasswordDialog.component";
import { User } from "../../../models/user/user";
import { useApi } from "../../common/providers/Api.provider";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

interface MenuItem {
  text: string;
  href?: string;
  icon: any;
  onClick?: MouseEventHandler<Element>;
  roles: UserRole[];
}

export function SideMenuComponent() {
  const api = useApi();
  const [user, setUser] = useUser();
  const history = useHistory();

  const [userToChangePasswordState, setUserToChangePasswordState] = useState<
    User | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSettingsMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItem[] = [
    {
      text: "Home",
      icon: <HomeIcon />,
      href: "/",
      roles: [UserRole.Admin, UserRole.Researcher, UserRole.User],
    },
    {
      text: "Vragenlijsten",
      icon: <AssignmentIcon />,
      href: "/questionnaires",
      roles: [UserRole.Admin, UserRole.Researcher],
    },
    {
      text: "Scans",
      icon: <AssessmentIcon />,
      href: "/scans",
      roles: [UserRole.Admin, UserRole.Researcher, UserRole.User],
    },
    {
      text: "Gebruikers",
      icon: <PeopleIcon />,
      href: "/users",
      roles: [UserRole.Admin],
    },
    {
      text: "Bedrijven",
      icon: <LocationCityIcon />,
      href: "/companies",
      roles: [UserRole.Admin],
    },
    {
      text: "Data",
      icon: <SaveAltIcon />,
      href: "/data",
      roles: [UserRole.Admin, UserRole.Researcher],
    },
    {
      text: "Contact",
      icon: <PermContactCalendarIcon />,
      href: "/contact",
      roles: [UserRole.Admin, UserRole.Researcher, UserRole.User],
    },
  ];

  const footerMenuItems: MenuItem[] = [
    {
      text: "Uitloggen",
      icon: <LogoutIcon />,
      onClick: async () => {
        await api.logout();
        setUser(null);
        history.push("/login");
      },
      roles: [UserRole.Admin, UserRole.Researcher, UserRole.User],
    },
  ];

  return (
    <>
      <UserChangePasswordDialogComponent
        open={!!userToChangePasswordState}
        user={userToChangePasswordState}
        handleClose={() => setUserToChangePasswordState(undefined)}
      />
      <div className={"item-list"}>
        <div className={"user-information-container"}>
          <div className={"user-information-container__upper"}>
            <AccountCircleIcon fontSize="large" />
            <span>
              {user?.firstName} {user?.lastName}
            </span>
          </div>
          <div className={"user-information-container__lower"}>
            {!!user?.company && !!user?.company.name && (
              <>
                <span>{user?.company.name}</span>
                <span
                  className={"user-information-container__lower__seperator"}
                >
                  {" "}
                  -{" "}
                </span>
              </>
            )}
            <span className={"user-information-container__lower__role"}>
              {convertUserRoleToText(user?.role)}
            </span>
            <IconButton
              className={"user-information-container__lower__settings"}
              onClick={handleSettingsMenuClick}
            >
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              id="settings-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleSettingsMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleSettingsMenuClose();
                  setUserToChangePasswordState(user);
                }}
              >
                Wachtwoord wijzigen
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Divider />
        <List>
          {menuItems
            .filter((menuItem) =>
              menuItem.roles.some((role) => role === user.role)
            )
            .map((menuItem, index) => (
              <ListItem
                button
                key={index}
                onClick={menuItem.onClick}
                {...(menuItem.href && {
                  component: NavLink,
                  to: menuItem.href,
                  exact: true,
                  activeClassName: "active",
                })}
              >
                <ListItemIcon className={"item-list__icon"}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.text} />
              </ListItem>
            ))}
        </List>
        <span className="spacer" />
        <a
          target="_blank"
          href="https://brainporteindhoven.com/nl/disclaimer-en-privacy"
          className={"privacy-statement"}
          rel="noopener noreferrer"
        >
          Privacyverklaring
        </a>
        <Divider />
        <List>
          {footerMenuItems
            .filter((menuItem) =>
              menuItem.roles.some((role) => role === user.role)
            )
            .map((menuItem, index) => (
              <ListItem
                button
                key={index}
                onClick={menuItem.onClick}
                {...(menuItem.href && {
                  component: NavLink,
                  to: menuItem.href,
                  activeClassName: "active",
                })}
              >
                <ListItemIcon className={"item-list__icon"}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.text} />
              </ListItem>
            ))}
        </List>
      </div>
    </>
  );
}
