import React, { useState } from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface QuestionnaireNewRevisionDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (parentId?: number) => void;
}

export function QuestionnaireNewRevisionDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireNewRevisionDialogComponentProps) {
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleCopy = async (questionnaire?: Questionnaire) => {
    if (!questionnaire || !questionnaire.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      await api.questionnairesCopy(questionnaire.id, {
        archiveSource: true,
      });

      handleClose(questionnaire.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          `Maak een nieuwe revisie aan`
        )}
      </DialogTitle>
      <DialogContent>
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <>
            <p>
              Een nieuwe revisie zou aangemaakt kunnen worden voor verschillende
              toetsmomenten binnen een scan.
            </p>
            <p>
              Door op "Verder gaan" te klikken wordt de oude vragenlijst buiten
              gebruik gesteld, er kunnen hierdoor geen nieuwe scans voor de oude
              vragenlijst worden aangemaakt.
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleCopy(questionnaire)} color="primary">
            Verder gaan
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
