import { Question } from "../models/question/question";
import { QuestionType } from "../models/question/questionType";
import { LikertScaleData } from "../models/question/likertScaleData";
import { PolarData } from "../models/question/polarData";
import { SelectData, SELECT_TYPE } from "../models/question/selectData";

export function questionsMaxScore(questions: Question[]) {
  return questions.reduce((result, question) => {
    return result + questionMaxScore(question);
  }, 0);
}

export function questionMaxScore(question: Question): number {
  switch (question.type) {
    case QuestionType.LIKERT_SCALE:
      const likertData = question.data as LikertScaleData;

      if (likertData.rows !== undefined && likertData.columns !== undefined) {
        const maxValue = Math.max(
          ...likertData.columns.map((c) => Number(c.score))
        );

        return likertData.rows.length * maxValue;
      }
      break;
    case QuestionType.POLAR:
      return (question.data as PolarData).score || 0;
    case QuestionType.SELECT:
      const selectData = question.data as SelectData;
      if (selectData.options) {
        switch (selectData.subType) {
          case SELECT_TYPE.DROPDOWN:
          case SELECT_TYPE.RADIO:
            return Math.max(...selectData.options.map((o) => Number(o.score)));
          case SELECT_TYPE.MULTI:
            return selectData.options.reduce(
              (result, o) => result + Number(o.score),
              0
            );
        }
      }
  }

  return 0;
}
