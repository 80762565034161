import React, { useState } from "react";
import {
  TextField,
  Button,
  CardContent,
  Card,
  CardActions,
  Typography,
} from "@material-ui/core";
import { useUser } from "../common/providers/User.provider";
import { useHistory, useLocation } from "react-router-dom";
import "./Login.component.sass";
import { LoginDto } from "../../models/user/loginDto";
import { useApi } from "../common/providers/Api.provider";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../lib/getSnackbarOptions";

export function LoginComponent() {
  const history = useHistory();
  const api = useApi();
  const snackbar = useSnackbar();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [, setUser] = useUser();
  const [loginLoadingState, setLoginLoadingState] = useState<boolean>(false);
  const [loginDtoState, setLoginDtoState] = useState<LoginDto>({
    username: "",
    password: "",
  });

  const handleLogin = async (event: any) => {
    event.preventDefault();

    try {
      setLoginLoadingState(true);

      const response = await api.login({
        ...loginDtoState, 
        scanUuid: queryParams.get('scanUuid') ?? undefined,
      });

      if (!response.success) {
        throw response.message;
      }

      setUser(response.data);
      history.push("/");
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
    } finally {
      setLoginLoadingState(false);
    }
  };

  return (
    <div className={"login-container"}>
      <span className={"login-container__title"}>
        International Talent Scan
      </span>
      <form onSubmit={handleLogin}>
        <Card>
          <CardContent>
            <Typography variant="body2" paragraph gutterBottom>
              Welkom op de international talent scan, ontwikkeld door Brainport Development, Holland Expat Center South en Fontys Hogescholen met en voor werkgevers in de High Tech en Maakindustrie en IT in de Brainport regio en Noord-Brabant. 
            </Typography>
            <Typography variant="body2" paragraph gutterBottom>
              Heb je al een account log dan in. Ben je een nieuwe organisatie, maak dan hier je account aan.
            </Typography>
            <Typography variant="body2" paragraph gutterBottom>
              Neem voor meer informatie contact op met Brainport Development en Holland Expat Center South via <a href="mailto:talent@brainporteindhoven.com">talent@brainporteindhoven.com</a>
            </Typography>
            <TextField
              autoFocus
              fullWidth
              required
              variant="outlined"
              margin="dense"
              id="username"
              label="Email"
              type="email"
              value={loginDtoState.username}
              tabIndex={0}
              onChange={(event) =>
                setLoginDtoState({
                  ...loginDtoState,
                  username: event.target.value,
                })
              }
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              margin="dense"
              id="password"
              label="Wachtwoord"
              type="password"
              value={loginDtoState.password}
              tabIndex={1}
              onChange={(event) =>
                setLoginDtoState({
                  ...loginDtoState,
                  password: event.target.value,
                })
              }
            />
          </CardContent>
          <CardActions>
            <Button disabled={loginLoadingState} type="submit">inloggen</Button>
            <Button
              onClick={() => {
                history.push("/register");
              }}
            >
              registreren
            </Button>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}
