import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "../common/loader/Loader.component";
import { useApi } from "../common/providers/Api.provider";
import "./RegisterActivate.component.sass";
import { Error } from "../common/error/Error.component";

export function RegisterActivateComponent() {
  const history = useHistory();
  const api = useApi();
  
  const location = useLocation();

  // eslint-disable-next-line
  const [_, uuid] = location.search.split('uuid=');

  const [isLoadingState, setIsLoadingState] = useState(false);

  const [counterState, setCounterState] = useState(10); 

  const activateUserByUUID = () => {
    try {
      setIsLoadingState(true);

      api.activateUserByUUID(uuid);

    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }

    setInterval(() => {
      setCounterState(prevState => prevState - 1);
    }, 1000);  
  };

  useEffect(() => {
    activateUserByUUID();

    return () => {
      clearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (counterState === 0) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterState]);

  if (!uuid) {
    return (<Error />);
  }

  if (isLoadingState) {
    return <Loader />;
  }

  return (
    <div className={"register-activate-container"}>
    <div className={"register-activate-container__content"}>
      <Card className={"card"}>
        <CardContent>
          <Typography variant="h1">
            Account geactiveerd!
          </Typography>
          <Typography>
            Je kunt nu gebruik maken van je account.
          </Typography>
          <Typography>
            Je wordt over <span className={"highlight"}>{counterState}</span> seconden doorverwezen naar de inlog pagina.
          </Typography>
        </CardContent>
      </Card>
    </div>
  </div>
  );
}
