import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
  Paper,
  Button,
} from "@material-ui/core";
import { Company } from "../../../../../../models/company/company";
import { Loader } from "../../../../../common/loader/Loader.component";
import { Error } from "../../../../../common/error/Error.component";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import "./CompanyList.component.sass";
import AddIcon from "@material-ui/icons/Add";
import { CompanyAddDialogComponent } from "../dialog/company-add-dialog/CompanyAddDialog.component";
import { CompanyUpdateDialogComponent } from "../dialog/company-update-dialog/CompanyUpdateDialog.component";
import { CompanyDeleteDialogComponent } from "../dialog/company-delete-dialog/CompanyDeleteDialog.component";
import { CompanyListItemComponent } from "../company-list-item/CompanyListItem.component";
import { useApi } from "../../../../../common/providers/Api.provider";

export function CompanyListComponent() {
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [companiesState, setCompaniesState] = useState<Company[]>([]);

  const [
    isCompanyAddDialogOpenState,
    setIsCompanyAddDialogOpenState,
  ] = useState<boolean>(false);

  const [companyToUpdateState, setCompanyToUpdateState] = useState<
    Company | undefined
  >(undefined);

  const [companyToDeleteState, setCompanyToDeleteState] = useState<
    Company | undefined
  >(undefined);

  const getCompanies = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.companies();

      setCompaniesState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCompanyAddDialog = (result?: Company) => {
    if (!!result) {
      setCompaniesState([...companiesState, result]);
    }

    setIsCompanyAddDialogOpenState(false);
  };

  const handleCloseCompanyUpdateDialog = (result?: Company) => {
    if (!!result) {
      setCompaniesState(
        companiesState.map((c) =>
          c.id === result.id ? { ...c, ...result } : c
        )
      );
    }

    setCompanyToUpdateState(undefined);
  };

  const handleCloseCompanyDeleteDialog = (result?: number) => {
    if (!!result) {
      setCompaniesState(companiesState.filter((c) => c.id !== result));
    }

    setCompanyToDeleteState(undefined);
  };

  if (isLoadingState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <CompanyAddDialogComponent
        open={isCompanyAddDialogOpenState}
        handleClose={handleCloseCompanyAddDialog}
      />
      <CompanyUpdateDialogComponent
        open={!!companyToUpdateState}
        company={companyToUpdateState}
        handleClose={handleCloseCompanyUpdateDialog}
      />
      <CompanyDeleteDialogComponent
        open={!!companyToDeleteState}
        company={companyToDeleteState}
        handleClose={handleCloseCompanyDeleteDialog}
      />
      <div className={"company-list-container"}>
        <div className={"company-list-container__header"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <LocationCityIcon />
              Bedrijven
            </Link>
          </Breadcrumbs>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsCompanyAddDialogOpenState(true)}
          >
            <AddIcon />
            Nieuw bedrijf toevoegen
          </Button>
        </div>
        <TableContainer
          className={"company-list-container__table"}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Telefoonnummer</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Acties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesState.map((row, index) => (
                <CompanyListItemComponent
                  key={index}
                  company={row}
                  setCompanyToUpdate={setCompanyToUpdateState}
                  setCompanyToDelete={setCompanyToDeleteState}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
