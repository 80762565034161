import React from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Question } from "../../../../../models/question/question";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import { PolarData } from "../../../../../models/question/polarData";

export interface PolarTypeComponentProps {
  question?: Question;
  scanValue?: ScanValue;
  setScanValue?: (value: ScanValue) => void;
}

export function PolarTypeComponent({
  question,
  scanValue,
  setScanValue,
}: PolarTypeComponentProps) {
  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;
  const polarData = q.data as PolarData;

  return (
    <FormControl component="fieldset" required={q.required}>
      <RadioGroup
        value={!!scanValue ? scanValue.value : null}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!!scanValue && !!setScanValue) {
            const value = (event.target as HTMLInputElement).value;

            setScanValue({
              ...scanValue,
              value,
              score: value === "true" ? polarData.score : null,
            });
          }
        }}
      >
        <FormControlLabel value="true" control={<Radio />} label="Ja" />
        <FormControlLabel value="false" control={<Radio />} label="Nee" />
      </RadioGroup>
    </FormControl>
  );
}
