import { testEmail } from "../../../../lib/testEmail";

export interface StepThreeState {
  firstName: string;
  firstNameError?: string;

  lastName: string;
  lastNameError?: string;
  
  email: string;
  emailError?: string;

  password: string;
  passwordError?: string;

  repeatPassword: string;
  repeatPasswordError?: string;
}

export const initialStepThreeState: StepThreeState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  repeatPassword: "",
}; 

export function validateStepThreeState(stepThreeState: StepThreeState): StepThreeState {
  stepThreeState.firstNameError = stepThreeState.firstName.length === 0 ? 'Een voornaam is verplicht.' : undefined;
  stepThreeState.lastNameError = stepThreeState.lastName.length === 0 ? 'Een achternaam is verplicht.' : undefined;
  stepThreeState.passwordError = stepThreeState.password.length === 0 ? 'Een wachtwoord is verplicht.' : undefined;
  
  if (stepThreeState.email.length === 0) {
    stepThreeState.emailError = 'Email is verplicht.';
  } else if (stepThreeState.email.length > 0 && !testEmail(stepThreeState.email)) {
    stepThreeState.emailError = 'Geen geldig emailadres.';
  } else {
    stepThreeState.emailError = undefined;
  }

  if (stepThreeState.repeatPassword.length === 0) {
    stepThreeState.repeatPasswordError = 'Vul uw wachtwoord nog een keer in.';
  } else if (stepThreeState.repeatPassword.length > 0 && stepThreeState.repeatPassword !== stepThreeState.password) {
    stepThreeState.repeatPasswordError = 'Uw wachtwoorden moeten overeenkomen.';
  } else {
    stepThreeState.repeatPasswordError = undefined;
  }

  return stepThreeState;
}

export function isStepThreeStateValid(stepThreeState: StepThreeState): boolean {
  return !stepThreeState.firstNameError && !stepThreeState.lastNameError && !stepThreeState.emailError && !stepThreeState.passwordError && !stepThreeState.repeatPasswordError;
}