import React, { useState, useEffect } from "react";
import { User } from "../../../../../../models/user/user";
import { useSnackbar } from "notistack";
import { UserChangePasswordDto } from "../../../../../../models/user/userChangePasswordDto";
import { useUser } from "../../../../../common/providers/User.provider";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import "./UserChangePasswordDialog.component.sass";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface UserChangePasswordDialogComponentProps {
  open: boolean;
  user?: User;
  handleClose: () => void;
}

export function UserChangePasswordDialogComponent({
  open,
  user,
  handleClose,
}: UserChangePasswordDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [authenticatedUser] = useUser();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [
    userChangePasswordDtoState,
    setUserChangePasswordDtoState,
  ] = useState<UserChangePasswordDto | null>(null);

  const isAuthenticatedUser = () =>
    !!user ? authenticatedUser.id === user.id : false;

  const isFormInvalid = () =>
    !user ||
    !userChangePasswordDtoState ||
    (isAuthenticatedUser() &&
      !userChangePasswordDtoState.oldPassword!.length) ||
    userChangePasswordDtoState.newPassword !==
      userChangePasswordDtoState.repeatNewPassword ||
    !userChangePasswordDtoState.newPassword.length ||
    !userChangePasswordDtoState.repeatNewPassword.length;

  const handleChangePassword = async (
    userChangePasswordDto: UserChangePasswordDto
  ) => {
    if (isFormInvalid()) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersChangePassword(
        user!.id,
        userChangePasswordDto
      );

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({
            variant: "success",
          })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({
            variant: "error",
          })
        );
      }

      handleClose();
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
    } finally {
      setUserChangePasswordDtoState(null);
      setIsLoadingState(false);
    }
  };

  const renderOldPassword = () => {
    if (!isAuthenticatedUser()) {
      return;
    }

    if (!userChangePasswordDtoState) {
      return <Skeleton variant="text" />;
    }

    return (
      <TextField
        autoFocus
        fullWidth
        required
        variant="outlined"
        margin="dense"
        id="oldPassword"
        label="Oud wachtwoord"
        type="password"
        value={userChangePasswordDtoState.oldPassword}
        onChange={(event) =>
          setUserChangePasswordDtoState({
            ...userChangePasswordDtoState,
            oldPassword: event.target.value,
          })
        }
      />
    );
  };

  useEffect(() => {
    if (!!user) {
      setUserChangePasswordDtoState({
        oldPassword: "",
        newPassword: "",
        repeatNewPassword: "",
      });
    }
  }, [user]);

  return (
    <Dialog open={open} className={"user-change-password-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!userChangePasswordDtoState ? (
          <Skeleton variant="text" />
        ) : (
          `Wachtwoord veranderen van ${user?.firstName} ${user?.lastName}`
        )}
      </DialogTitle>
      <DialogContent>
        {renderOldPassword()}
        {!userChangePasswordDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus={!isAuthenticatedUser()}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="newPassword"
            label="Nieuwe wachtwoord"
            type="password"
            value={userChangePasswordDtoState.newPassword}
            onChange={(event) =>
              setUserChangePasswordDtoState({
                ...userChangePasswordDtoState,
                newPassword: event.target.value,
              })
            }
          />
        )}
        {!userChangePasswordDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="repeatNewPassword"
            label="Herhaal het nieuwe wachtwoord"
            type="password"
            value={userChangePasswordDtoState.repeatNewPassword}
            onChange={(event) =>
              setUserChangePasswordDtoState({
                ...userChangePasswordDtoState,
                repeatNewPassword: event.target.value,
              })
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!userChangePasswordDtoState ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose()} color="secondary">
            Annuleer
          </Button>
        )}
        {!userChangePasswordDtoState ? (
          <Skeleton variant="rect" />
        ) : (
          <Button
            disabled={isFormInvalid()}
            onClick={() => handleChangePassword(userChangePasswordDtoState)}
            color="primary"
          >
            Bijwerken
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
