import React from "react";
import { CircularProgress, CircularProgressProps } from "@material-ui/core";
import "./Loader.component.sass";

export interface LoaderProps {
  loadingText?: string;
}

export function Loader({
  loadingText,
  ...props
}: LoaderProps & CircularProgressProps) {
  return (
    <div className={"loader-container"}>
      <CircularProgress {...props} />
      {!!loadingText && (
        <span className={"loader-container__text"}>{loadingText}</span>
      )}
    </div>
  );
}
