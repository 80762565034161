import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Category } from "../../../../../../../models/category/category";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface CategoryDeleteDialogComponentProps {
  open: boolean;
  categoryId?: number;
  handleClose: (result?: number) => void;
}

export function CategoryDeleteDialogComponent({
  open,
  categoryId,
  handleClose,
}: CategoryDeleteDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [nameState, setNameState] = useState<string>("");
  const [categoryState, setCategoryState] = useState<Category | undefined>(
    undefined
  );

  const getCategory = async (categoryId: number) => {
    try {
      const response = await api.categoriesById(categoryId);

      setCategoryState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (category?: Category) => {
    if (!category || !category.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.categoriesDelete(category.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(category.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  const renderContentText = () => {
    if (!categoryState) {
      return;
    }

    if (!categoryState.steps.length) {
      return `Voor het verwijderen van de categorie "${categoryState.name}" moet de
      naam herhaald worden.`;
    }

    return (
      <>
        <p>Deze categorie is gekoppeld aan: </p>
        <ul>
          {categoryState.steps.map((step, index) => (
            <li key={index}>{step.name}</li>
          ))}
        </ul>
        <p>
          Hierdoor kan de categorie niet verwijderd worden. Zorg dat de stappen
          eerst onder een andere categorie vallen.
        </p>
      </>
    );
  };

  const renderNameFormField = () => {
    if (!categoryState) {
      return <Skeleton variant="text" />;
    }

    if (!categoryState.steps.length) {
      return (
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Herhaal de categorie naam"
          type="text"
          value={nameState}
          onChange={(event) => setNameState(event.target.value)}
        />
      );
    }
  };

  const renderDeleteButton = () => {
    if (!categoryState) {
      return <Skeleton variant="rect" />;
    }

    if (!categoryState.steps.length) {
      return (
        <Button
          disabled={categoryState.name !== nameState}
          onClick={() => handleDelete(categoryState)}
          color="primary"
        >
          Verwijder
        </Button>
      );
    }
  };

  useEffect(() => {
    if (!!categoryId) {
      getCategory(categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!categoryState ? (
          <Skeleton variant="text" />
        ) : (
          `Verwijder ${categoryState.name}`
        )}
      </DialogTitle>
      <DialogContent>
        {!categoryState ? <Skeleton variant="rect" /> : renderContentText()}
        {renderNameFormField()}
      </DialogContent>
      <DialogActions>
        {!categoryState ? (
          <Skeleton variant="rect" />
        ) : (
          <Button
            onClick={() => {
              setNameState("");
              handleClose(undefined);
            }}
            color="secondary"
          >
            Annuleer
          </Button>
        )}
        {renderDeleteButton()}
      </DialogActions>
    </Dialog>
  );
}
