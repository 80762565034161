import React, { createContext, useContext } from "react";
import { User } from "../../../models/user/user";
import { API_URL } from "../../../lib/constants";
import { LoginDto } from "../../../models/user/loginDto";
import { Company } from "../../../models/company/company";
import { CompanyAddDto } from "../../../models/company/companyAddDto";
import { CompanyUpdateDto } from "../../../models/company/companyUpdateDto";
import { Questionnaire } from "../../../models/questionnaire/questionnaire";
import { Category } from "../../../models/category/category";
import { QuestionnaireUpdateDto } from "../../../models/questionnaire/questionnaireUpdateDto";
import { CategoryAddDto } from "../../../models/category/categoryAddDto";
import { CategoryUpdateDto } from "../../../models/category/categoryUpdateDto";
import { StepAddDto } from "../../../models/step/stepAddDto";
import { Step } from "../../../models/step/step";
import { StepUpdateDto } from "../../../models/step/stepUpdateDto";
import { Question } from "../../../models/question/question";
import { QuestionnaireAddDto } from "../../../models/questionnaire/questionnaireAddDto";
import { Scan } from "../../../models/scan/scan";
import { ScanAddDto } from "../../../models/scan/scanAddDto";
import { ScanValue } from "../../../models/scan-value/scanValue";
import { CategoryTips } from "../../scan/scan-result-page/categoryTips";
import { CategoryAdvices } from "../../scan/scan-result-page/categoryAdvices";
import { UserAddDto } from "../../../models/user/userAddDto";
import { UserChangePasswordDto } from "../../../models/user/userChangePasswordDto";
import { UserUpdateDto } from "../../../models/user/userUpdateDto";
import { RegisterDto } from "../../../models/user/registerDto";
import { PostalCode } from "../../../models/postal-code/postalCode";

export interface ApiResponse<T = any> {
  success: boolean;
  message: string;
  data: T;
}

export class Api {
  private call<T = any>(
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any
  ): Promise<ApiResponse<T>> {
    return fetch(`${API_URL}${path}`, {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => (res.json() as unknown) as ApiResponse<T>);
  }

  // AUTH CALLS
  login(loginDto: LoginDto): Promise<ApiResponse<User>> {
    return this.call<User>("/auth/login", "POST", loginDto);
  }

  async logout(): Promise<void> {
    await fetch(`${API_URL}/auth/logout`, {
      method: "GET",
      credentials: "include",
    });
  }

  register(registerDto: RegisterDto): Promise<ApiResponse<void>> {
    return this.call<void>("/auth/register", "POST", registerDto);
  }

  // USER CALLS
  users(): Promise<ApiResponse<User[]>> {
    return this.call<User[]>("/users", "GET");
  }

  usersAdd(userAddDto: UserAddDto): Promise<ApiResponse<User>> {
    return this.call<User>("/users", "POST", userAddDto);
  }

  usersUpdate(userUpdateDto: UserUpdateDto): Promise<ApiResponse<User>> {
    return this.call<User>(`/users/${userUpdateDto.id}`, "PUT", userUpdateDto);
  }

  usersMe(): Promise<ApiResponse<User>> {
    return this.call<User>("/users/me", "GET");
  }

  usersChangePassword(
    userId: number,
    changePasswordDto: UserChangePasswordDto
  ): Promise<ApiResponse> {
    return this.call(
      `/users/${userId}/change-password`,
      "PUT",
      changePasswordDto
    );
  }

  usersFilter(questionnaireIds: number[]): Promise<ApiResponse<User[]>> {
    return this.call<User[]>("/users/filter", "POST", { questionnaireIds });
  }

  usersDelete(userId: number): Promise<ApiResponse> {
    return this.call(`/users/${userId}`, "DELETE");
  }

  activateUserByUUID(uuid: string): Promise<ApiResponse<void>> {
    return this.call<void>(`/users/activate/${uuid}`, "PUT");
  }

  // COMPANIES CALLS
  companies(): Promise<ApiResponse<Company[]>> {
    return this.call<Company[]>("/companies", "GET");
  }

  companiesAdd(companyAddDto: CompanyAddDto): Promise<ApiResponse<Company>> {
    return this.call<Company>("/companies", "POST", companyAddDto);
  }

  companiesUpdate(
    companyUpdateDto: CompanyUpdateDto
  ): Promise<ApiResponse<Company>> {
    return this.call<Company>(
      `/companies/${companyUpdateDto.id}`,
      "PUT",
      companyUpdateDto
    );
  }

  companiesDelete(companyId: number): Promise<ApiResponse> {
    return this.call(`/companies/${companyId}`, "DELETE");
  }

  companiesFilter(): Promise<ApiResponse<Company[]>> {
    return this.call<Company[]>(`/companies/filter`, "GET");
  }

  companiesScansBranches(uuid: string): Promise<ApiResponse<string[]>> {
    return this.call<string[]>(
      `/companies/scans/${uuid}/result/branches`,
      "GET"
    );
  }

  postalCodes(): Promise<ApiResponse<PostalCode[]>> {
    return this.call<PostalCode[]>(`/companies/postal-codes`, "GET");
  }

  findCompanyByKvkNumber(kvkNumber: string): Promise<ApiResponse<Company | null>> {
    return this.call<Company | null>(`/companies/kvk-number/${kvkNumber}`, 'GET');
  }

  // QUESTIONNAIRES CALLS
  questionnaires(): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires", "GET");
  }

  questionnairesAdd(
    questionnaireAddDto: QuestionnaireAddDto
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      "/questionnaires",
      "POST",
      questionnaireAddDto
    );
  }

  questionnairesCopy(
    questionnaireId: number,
    copyDto: { archiveSource: boolean; newQuestionnaireName?: string }
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}/copy`,
      "PUT",
      copyDto
    );
  }

  questionnairesUpdate(
    questionnaireUpdateDto: QuestionnaireUpdateDto
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireUpdateDto.id}`,
      "PUT",
      questionnaireUpdateDto
    );
  }

  questionnairesDelete(questionnaireId: number): Promise<ApiResponse> {
    return this.call(`/questionnaires/${questionnaireId}`, "DELETE");
  }

  questionnairesPublished(): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires/published", "GET");
  }

  questionnairesSetPublished(
    questionnaireId: number,
    published: boolean
  ): Promise<ApiResponse> {
    return this.call(`/questionnaires/${questionnaireId}/published`, "PUT", {
      published,
    });
  }

  questionnairesById(
    questionnaireId: number
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}`,
      "GET"
    );
  }

  questionnairesByPublicCode(
    publicCode: string
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/public-code/${publicCode}`,
      "GET"
    );
  }

  questionnairesCheck(
    questionnaireId: number
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}/check`,
      "GET"
    );
  }

  questionnairesFilter(
    companyIds: number[]
  ): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires/filter", "POST", {
      companyIds,
    });
  }

  // CATEGORIES CALLS
  categoriesAdd(
    categoryAddDto: CategoryAddDto
  ): Promise<ApiResponse<Category>> {
    return this.call<Category>("/categories", "POST", categoryAddDto);
  }

  categoriesById(categoryId: number): Promise<ApiResponse<Category>> {
    return this.call<Category>(`/categories/${categoryId}`, "GET");
  }

  categoriesUpdate(
    categoryUpdateDto: CategoryUpdateDto
  ): Promise<ApiResponse<Category>> {
    return this.call<Category>(
      `/categories/${categoryUpdateDto.id}`,
      "PUT",
      categoryUpdateDto
    );
  }

  categoriesDelete(categoryId: number): Promise<ApiResponse> {
    return this.call(`/categories/${categoryId}`, "DELETE");
  }

  categoriesQuestionnaireById(
    questionnaireId: number
  ): Promise<ApiResponse<Category[]>> {
    return this.call<Category[]>(
      `/categories/questionnaire/${questionnaireId}`,
      "GET"
    );
  }

  // STEPS CALLS
  stepsAdd(stepAddDto: StepAddDto): Promise<ApiResponse<Step>> {
    return this.call<Step>("/steps", "POST", stepAddDto);
  }

  stepsUpdate(stepUpdateDto: StepUpdateDto): Promise<ApiResponse<Step>> {
    return this.call<Step>(`/steps/${stepUpdateDto.id}`, "PUT", stepUpdateDto);
  }

  stepsDelete(stepId: number): Promise<ApiResponse> {
    return this.call(`/steps/${stepId}`, "DELETE");
  }

  stepsQuestionnaireById(
    questionnaireId: number
  ): Promise<ApiResponse<Step[]>> {
    return this.call<Step[]>(`/steps/questionnaire/${questionnaireId}`, "GET");
  }

  stepsMove(stepId: number, moveDto: { order: number }): Promise<ApiResponse> {
    return this.call(`/steps/${stepId}/move`, "PUT", moveDto);
  }

  // QUESTIONS CALLS
  questionsAdd(questionsAddDto: {
    stepId: number;
    questions: Question[];
    maxScore: number;
  }): Promise<ApiResponse<Question[]>> {
    return this.call<Question[]>("/questions", "POST", questionsAddDto);
  }

  questionsStepById(stepId: number): Promise<ApiResponse<Question[]>> {
    return this.call<Question[]>(`/questions/step/${stepId}`, "GET");
  }

  // SCANS CALLS
  scans(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>("/scans", "GET");
  }

  scanByUUID(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}`, "GET");
  }

  scanAdd(scanAddDto: ScanAddDto): Promise<ApiResponse<Scan>> {
    console.log(scanAddDto);
    return this.call<Scan>("/scans", "POST", scanAddDto);
  }

  checkTimePeriod(questionnaireId: number): Promise<ApiResponse<boolean>> {
    return this.call<boolean>(`/scans/check/time-period/${questionnaireId}`, "GET");
  }

  scansDelete(scanId: number): Promise<ApiResponse> {
    return this.call(`/scans/${scanId}`, "DELETE");
  }

  scansFinish(scanId: number, scan: Scan): Promise<ApiResponse> {
    return this.call(`/scans/${scanId}/finish`, "PUT", scan);
  }

  scansDrafts(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>(`/scans/drafts`, "GET");
  }

  scansHistory(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>("/scans/history", "GET");
  }

  scansResult(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}/result`, "GET");
  }

  scansResultPrevious(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}/result/previous`, "GET");
  }

  scansTips(uuid: string): Promise<ApiResponse<CategoryTips[]>> {
    return this.call<CategoryTips[]>(`/scans/${uuid}/result/tips`, "GET");
  }

  scansAdvices(uuid: string): Promise<ApiResponse<CategoryAdvices[]>> {
    return this.call<CategoryAdvices[]>(`/scans/${uuid}/result/advices`, "GET");
  }

  scansAverageBranches(
    scanUuid: string,
    branch: string
  ): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(
      `/scans/${scanUuid}/result/average/branches/${branch}`,
      "GET"
    );
  }

  // SCAN-VALUES CALLS
  scanValuesUUID(uuid: string): Promise<ApiResponse<ScanValue[]>> {
    return this.call<ScanValue[]>(`/scan-values/scan/${uuid}`, "GET");
  }

  scanValuesSave(saveDto: {
    scanId: number;
    scanValues: ScanValue[];
  }): Promise<ApiResponse<ScanValue[]>> {
    return this.call<ScanValue[]>("/scan-values", "POST", saveDto);
  }
}

const ApiStateContext = createContext<Api>(null as any);

export const ApiProvider: React.SFC = ({ children }) => {
  return (
    <ApiStateContext.Provider value={new Api()}>
      {children}
    </ApiStateContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiStateContext);
};
