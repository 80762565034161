import React, { useEffect, useState } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { QuestionnaireUpdateDto } from "../../../../../../models/questionnaire/questionnaireUpdateDto";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { Loader } from "../../../../../common/loader/Loader.component";
import "./Details.component.sass";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useApi } from "../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../lib/getCKEditorConfig";

export interface DetailsComponentProps {
  questionnaireId: string | undefined;
  onSaveChanges: (questionnaire: Questionnaire) => void;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DetailsComponent({
  questionnaireId,
  onSaveChanges,
  setHasError,
}: DetailsComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isDetailsLoadingState, setIsDetailsLoadingState] = useState<boolean>(
    false
  );

  const [
    questionnaireUpdateDtoState,
    setQuestionnaireUpdateDtoState,
  ] = useState<QuestionnaireUpdateDto | null>(null);

  const getQuestionnaire = async (questionnaireId: number) => {
    try {
      setIsDetailsLoadingState(true);
      const response = await api.questionnairesById(questionnaireId);

      setQuestionnaireUpdateDtoState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsDetailsLoadingState(false);
    }
  };

  const handleUpdate = async (
    questionnaireUpdateDto: QuestionnaireUpdateDto
  ) => {
    if (
      !questionnaireUpdateDto.id ||
      !questionnaireUpdateDto.name.length ||
      !questionnaireUpdateDto.description.length
    ) {
      return;
    }

    try {
      const response = await api.questionnairesUpdate(questionnaireUpdateDto);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      setQuestionnaireUpdateDtoState(response.data);
      onSaveChanges(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!!questionnaireId) {
      getQuestionnaire(Number(questionnaireId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  return (
    <Paper className={"questionnaire-details-container"}>
      {isDetailsLoadingState || !questionnaireUpdateDtoState ? (
        <Loader loadingText="Informatie aan het laden..." />
      ) : (
        <>
          <span className={"questionnaire-details-container__title"}>
            Vragenlijst details
          </span>
          <TextField
            className={"questionnaire-details-container__form-control"}
            fullWidth
            variant="outlined"
            label="Naam"
            value={questionnaireUpdateDtoState.name}
            onChange={(event) =>
              setQuestionnaireUpdateDtoState({
                ...questionnaireUpdateDtoState,
                name: event.target.value,
              })
            }
          />
          <CKEditor
            editor={ClassicEditor}
            data={questionnaireUpdateDtoState.description}
            config={{ ...getCKEditorConfig(), placeholder: "Beschrijving *" }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setQuestionnaireUpdateDtoState({
                ...questionnaireUpdateDtoState,
                description: data,
              });
            }}
          />

          <div className={"questionnaire-details-container__button-container"}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleUpdate(questionnaireUpdateDtoState)}
            >
              Sla wijzigingen op
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
}
