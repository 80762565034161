import React, { useState, useEffect } from "react";
import { Scan } from "../../../../../../models/scan/scan";
import { useSnackbar } from "notistack";
import { ScanAddDto } from "../../../../../../models/scan/scanAddDto";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface ScanAddDialogComponent {
  open: boolean;
  handleClose: (result?: Scan) => void;
}

export function ScanAddDialogComponent({
  open,
  handleClose,
}: ScanAddDialogComponent) {
  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState = { questionnaireId: 0 };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [scanAddDtoState, setScanAddDtoState] = useState<ScanAddDto>(
    emptyState
  );

  const [questionnairesState, setQuestionnairesState] = useState<
    Questionnaire[]
  >([]);

  const [userAcceptedPrivacyPolicy, setUserAcceptedPrivacyPolicy] = useState<boolean>(false);

  const [userAcceptedDataProcessing, setUserAcceptedDataProcessing] = useState<boolean>(false);

  const getQuestionnaires = async () => {
    try {
      const response = await api.questionnairesPublished();

      setQuestionnairesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setScanAddDtoState({
      ...scanAddDtoState,
      questionnaireId: Number(event.target.value),
    });
  };

  const handleAdd = async (scanAddDtoState: ScanAddDto) => {
    if (!scanAddDtoState.questionnaireId) {
      return;
    }

    try {
      setIsLoadingState(true);
      
      // const responseCheckTimePeriod = await api.checkTimePeriod(scanAddDtoState.questionnaireId);

      // if (!responseCheckTimePeriod.success) {
      //   snackbar.enqueueSnackbar(
      //     responseCheckTimePeriod.message,
      //     getSnackbarOptions({ variant: "error" })
      //   );  

      //   handleClose(undefined);

      //   return;
      // }

      const responseScanAdd = await api.scanAdd(scanAddDtoState);

      if (responseScanAdd.success) {
        snackbar.enqueueSnackbar(
          responseScanAdd.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          responseScanAdd.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(responseScanAdd.data);
    } catch (e) {
      snackbar.enqueueSnackbar(e as string, getSnackbarOptions({ variant: "error" }));
    } finally {
      setScanAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>Maak een nieuwe scan aan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecteer een vragenlijst waar u de scan voor wilt starten.
        </DialogContentText>
        {!questionnairesState.length && (
          <>Er zijn geen vragenlijsten beschikbaar.</>
        )}
        {questionnairesState.length > 0 && (
          <>
            <TextField
              autoFocus
              fullWidth
              required
              select
              SelectProps={{
                displayEmpty: true,
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              margin="dense"
              id="questionnaireId"
              label="Vragenlijst"
              value={scanAddDtoState.questionnaireId}
              onChange={handleChange}
            >
              <option value="">Kies een vragenlijst</option>
              {questionnairesState.map((questionnaire, index) => (
                <option key={index} value={questionnaire.id}>
                  {questionnaire.name} - Revisie {questionnaire.revision}
                </option>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Checkbox
                  required 
                  color="secondary"
                  checked={userAcceptedPrivacyPolicy} 
                  onChange={(event, checked) => {
                    setUserAcceptedPrivacyPolicy(checked);
                  }}
                />
              }
              label={<span>Ik heb de <a target="_blank" href="https://brainporteindhoven.com/nl/disclaimer-en-privacy" rel="noopener noreferrer">privacyverklaring</a> gelezen en ga hiermee akkoord</span>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  required 
                  color="secondary"
                  checked={userAcceptedDataProcessing} 
                  onChange={(event, checked) => {
                    setUserAcceptedDataProcessing(checked);
                  }}
                />
              }
              label="Ik ga akkoord met de dataverwerking door Fontys Hogescholen, Holland Expat Center South en Brainport Development"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          Annuleer
        </Button>
        <Button
          onClick={() => handleAdd(scanAddDtoState)}
          color="primary"
          disabled={!scanAddDtoState.questionnaireId || !userAcceptedDataProcessing || !userAcceptedPrivacyPolicy}
        >
          Scan starten
        </Button>
      </DialogActions>
    </Dialog>
  );
}
