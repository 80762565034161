import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Step } from "../../../../../../../models/step/step";
import { StepUpdateDto } from "../../../../../../../models/step/stepUpdateDto";
import { Skeleton } from "@material-ui/lab";
import "./StepUpdateDialog.component.sass";
import { Category } from "../../../../../../../models/category/category";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../../lib/getCKEditorConfig";

export interface StepUpdateDialogComponentProps {
  open: boolean;
  step?: Step;
  handleClose: (result?: Step) => void;
}

export function StepUpdateDialogComponent({
  open,
  step,
  handleClose,
}: StepUpdateDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [
    stepUpdateDtoState,
    setStepUpdateDtoState,
  ] = useState<StepUpdateDto | null>(null);

  const [categoriesState, setCategoriesState] = useState<Category[]>([]);

  const getCategories = async (questionnaireId: number) => {
    try {
      const response = await api.categoriesQuestionnaireById(questionnaireId);

      setCategoriesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdate = async (stepUpdateDto: StepUpdateDto) => {
    if (
      !stepUpdateDto.id ||
      !stepUpdateDto.name.length ||
      !stepUpdateDto.description.length ||
      !stepUpdateDto.categoryId
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.stepsUpdate(stepUpdateDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setStepUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open && !!step) {
      setStepUpdateDtoState(step);
      getCategories(step.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, step]);

  return (
    <Dialog open={open} className={"step-update-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!stepUpdateDtoState ? <Skeleton variant="text" /> : "Bewerk een stap"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!stepUpdateDtoState ? (
            <Skeleton variant="rect" />
          ) : (
            "Een stap bewerken kan door de naam en beschrijving aan te passen. "
          )}
        </DialogContentText>
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            className={"mb"}
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Naam"
            type="text"
            value={stepUpdateDtoState.name}
            onChange={(event) =>
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                name: event.target.value,
              })
            }
          />
        )}
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <CKEditor
            className={"mb"}
            editor={ClassicEditor}
            data={stepUpdateDtoState.description}
            config={{ ...getCKEditorConfig(), placeholder: "Beschrijving *" }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                description: data,
              });
            }}
          />
        )}
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            className={"mt mb"}
            fullWidth
            select
            margin="dense"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
            id="categoryId"
            label="Categorie"
            required
            value={stepUpdateDtoState.categoryId}
            onChange={(event) => {
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                categoryId: Number(event.target.value),
              });
            }}
          >
            <MenuItem value={0}>Kies een optie</MenuItem>
            {categoriesState.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {!stepUpdateDtoState || !step ? (
          <Skeleton variant="text" />
        ) : (
          <>
            <TextField
              className={"mb"}
              fullWidth
              margin="dense"
              variant="outlined"
              label="Tips tonen onder score van"
              type="number"
              value={stepUpdateDtoState.showTipsScore ?? ""}
              inputProps={{ min: 0, max: step.maxScore }}
              onChange={(event) => {
                setStepUpdateDtoState({
                  ...stepUpdateDtoState,
                  showTipsScore: (event.target.value
                    ? Number(event.target.value)
                    : null) as any,
                });
              }}
            />
            <div className={"mb"}>
              <span>Maximale score: {step.maxScore}</span>
            </div>
          </>
        )}
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <CKEditor
            className={"mb"}
            editor={ClassicEditor}
            data={stepUpdateDtoState.advice}
            config={{ ...getCKEditorConfig(), placeholder: "Advies" }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                advice: data,
              });
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!stepUpdateDtoState ? (
          <Skeleton variant="rect" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              Annuleer
            </Button>
            <Button
              onClick={() => handleUpdate(stepUpdateDtoState)}
              color="primary"
            >
              Bewerken
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
