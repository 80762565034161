import React, { useEffect } from "react";
import { Question } from "../../../../../models/question/question";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import { LikertScaleData } from "../../../../../models/question/likertScaleData";
import { Radio, Tooltip } from "@material-ui/core";
import "./LikertScaleType.component.sass";
import InfoIcon from "@material-ui/icons/Info";

export interface LikertScaleTypeComponentProps {
  question?: Question;
  scanValue?: ScanValue;
  setScanValue?: (value: ScanValue) => void;
}

export function LikertScaleTypeComponent({
  question,
  scanValue,
  setScanValue,
}: LikertScaleTypeComponentProps) {
  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;
  const likertScaleData = q.data as LikertScaleData;

  const gridTemplateColumns = {
    gridTemplateColumns: `auto ${likertScaleData.columns
      .map(() => `calc((100% - 350px) / ${likertScaleData.columns.length})`)
      .join(" ")}`,
  };

  const serialize = (array: Array<number | undefined>): string =>
    array.join(",");

  const deserialize = (str: string): Array<number | undefined> =>
    str.split(",").map((s) => (!!s ? Number(s) : undefined));

  const renderHint = (hint: string) => {
    if (!hint || !hint.length) {
      return;
    }

    return (
      <Tooltip title={hint} placement="top">
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    );
  };

  const handleChange = (rowIndex: number, columnIndex: number) => {
    if (!!scanValue && !!setScanValue) {
      const values = deserialize(scanValue.value);
      values[rowIndex] = columnIndex;
      const score = values.reduce((total, current) => {
        let value = 0;

        if (current !== undefined) {
          value = Number(likertScaleData.columns[Number(current)].score);
        }

        return total !== undefined ? total + value : value;
      }, 0);

      setScanValue({
        ...scanValue,
        value: serialize(values),
        score: score !== undefined ? score : null,
      });
    }
  };

  useEffect(() => {
    if (!!scanValue && !!setScanValue && !scanValue.value.length) {
      setScanValue({
        ...scanValue,
        value: serialize(
          Array(likertScaleData.columns.length - 1).fill(undefined)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="likert-scale-type">
      <div className="likert-scale-type__row" style={gridTemplateColumns}>
        <div className="likert-scale-type__column__instruction-cell"></div>
        {likertScaleData.columns.map((column, index) => (
          <div className="likert-scale-type__column" key={index}>
            <div className="likert-scale-type__header">{column.label}</div>
          </div>
        ))}
      </div>
      {likertScaleData.rows.map((row, rowIndex) => (
        <div
          key={rowIndex}
          style={gridTemplateColumns}
          className={
            "likert-scale-type__row " +
            (rowIndex % 2 === 0
              ? "likert-scale-type__row__light-grey"
              : "likert-scale-type__row__grey")
          }
        >
          <div className="likert-scale-type__column__instruction-cell">
            <span>{row.instruction}</span>
            {renderHint(row.hint)}
          </div>
          {likertScaleData.columns.map((column, columnIndex) => (
            <div className="likert-scale-type__column" key={columnIndex}>
              <div className="likert-scale-type__content">
                <Radio
                  checked={
                    !!scanValue
                      ? deserialize(scanValue.value)[rowIndex] === columnIndex
                      : false
                  }
                  onChange={() => handleChange(rowIndex, columnIndex)}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
