import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Company } from "../../../../../../../models/company/company";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface CompanyDeleteDialogComponentProps {
  open: boolean;
  company?: Company;
  handleClose: (result?: number) => void;
}

export function CompanyDeleteDialogComponent({
  open,
  company,
  handleClose,
}: CompanyDeleteDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (company?: Company) => {
    if (!company || !company.id || company.name !== nameState) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.companiesDelete(company.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(company.id);
    } catch (e) {
      snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!company ? <Skeleton variant="text" /> : `Verwijder ${company.name}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!company ? (
            <Skeleton variant="rect" />
          ) : (
            `Voor het verwijderen van het bedrijf "${company.name}" moet de
          naam herhaald worden.`
          )}
        </DialogContentText>
        {!company ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Herhaal de bedrijfsnaam"
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!company ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!company ? (
          <Skeleton variant="rect" />
        ) : (
          <Button
            disabled={!company || company.name !== nameState}
            onClick={() => handleDelete(company)}
            color="primary"
          >
            Verwijder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
