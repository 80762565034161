import React from "react";
import { Question } from "../../../../../../../../models/question/question";
import { TextField, FormControlLabel, Switch } from "@material-ui/core";
import { OpenData } from "../../../../../../../../models/question/openData";

export interface OpenInputComponentProps {
  value: Question<OpenData>;
  setValue: (value: Question<OpenData>) => void;
}

export function OpenInputComponent({
  value,
  setValue,
}: OpenInputComponentProps) {
  return (
    <div className="open-input">
      <TextField
        fullWidth
        variant="outlined"
        label="Aanduiding"
        color="primary"
        className={"form-control"}
        value={value.data.placeholder || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({
            ...value,
            data: { ...value.data, placeholder: event.target.value },
          })
        }
      />
      <div>
        <FormControlLabel
          control={
            <Switch
              required
              checked={value.data.multilineAnswer || false}
              value={value.data.multilineAnswer || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue({
                  ...value,
                  data: {
                    ...value.data,
                    multilineAnswer: event.target.checked,
                  },
                })
              }
            />
          }
          label="Lang antwoord toestaan"
        />
        <FormControlLabel
          control={
            <Switch
              required
              checked={value.data.isTip || false}
              value={value.data.isTip || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue({
                  ...value,
                  data: { ...value.data, isTip: event.target.checked },
                })
              }
            />
          }
          label="Is een tip"
        />
      </div>
      {value.data.isTip && (
        <TextField
          fullWidth
          variant="outlined"
          label="Tip invullen vanaf"
          color="primary"
          className={"form-control"}
          value={value.data.tipMinScore ?? ""}
          type="number"
          inputProps={{ min: 0 }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue({
              ...value,
              data: {
                ...value.data,
                tipMinScore: Number(event.target.value) ?? undefined,
              },
            })
          }
        />
      )}
    </div>
  );
}
