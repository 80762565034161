import React from "react";
import { CompanyListComponent } from "./company-list/CompanyList.component";

export function CompanyListPageComponent() {
  return (
    <div className={"company-list-page-container page"}>
      <CompanyListComponent />
    </div>
  );
}
