import React from "react";
import { QuestionnaireListComponent } from "./questionnaire-list/QuestionnaireList.component";

export function QuestionnaireListPageComponent() {
  return (
    <div className={"questionnaire-list-page-container page"}>
      <QuestionnaireListComponent />
    </div>
  );
}
