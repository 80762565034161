import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Breadcrumbs,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Scan } from "../../../../../models/scan/scan";
import { Error } from "../../../../common/error/Error.component";
import { Loader } from "../../../../common/loader/Loader.component";
import "./ScanList.component.sass";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibleIcon from "@material-ui/icons/Visibility";
import { ScanAddDialogComponent } from "../dialog/scan-add-dialog/ScanAddDialog.component";
import { ScanDeleteDialogComponent } from "../dialog/scan-delete-dialog/ScanDeleteDialog.component";
import { useHistory } from "react-router-dom";
import { useApi } from "../../../../common/providers/Api.provider";

export function ScanListComponent() {
  const api = useApi();
  const history = useHistory();

  const [isScanDraftsLoadingState, setIsScanDraftsLoadingState] = useState<
    boolean
  >(false);
  const [isScanHistoryLoadingState, setIsScanHistoryLoadingState] = useState<
    boolean
  >(false);
  const [isErrorState, setIsErrorState] = useState<boolean>(false);

  const [scanDraftsState, setScanDraftsState] = useState<Scan[]>([]);
  const [scanHistoryState, setScanHistoryState] = useState<Scan[]>([]);

  const [isScanAddDialogOpenState, setIsScanAddDialogOpenState] = useState<
    boolean
  >(false);

  const [scanToDeleteState, setScanToDeleteState] = useState<Scan | undefined>(
    undefined
  );

  const navigateToScanDetailPage = (scanUuid: string) => {
    setTimeout(() => {
      history.push(`/scans/${scanUuid}`);
    });
  };

  const handleCloseScanAddDialog = (result?: Scan) => {
    setIsScanAddDialogOpenState(false);

    if (!!result) {
      navigateToScanDetailPage(result.uuid);
    }
  };

  const handleCloseScanDeleteDialog = (result?: number) => {
    if (!!result) {
      setScanDraftsState(scanDraftsState.filter((s) => s.id !== result));
    }

    setScanToDeleteState(undefined);
  };

  const renderScanDrafts = () => {
    if (isScanDraftsLoadingState) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!scanDraftsState.length) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            Er zijn op dit moment geen openstaande scans.
          </TableCell>
        </TableRow>
      );
    }

    return scanDraftsState.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.questionnaire.name}</TableCell>
        <TableCell>
          {row.createdByUser ? `${row.createdByUser.firstName} ${row.createdByUser.lastName}` : 'Anoniem'}
        </TableCell>
        <TableCell>{row.questionnaire.revision}</TableCell>
        <TableCell>
          {moment(row.startDate).format("DD-MM-YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Tooltip title="Verder gaan" placement="top">
            <IconButton onClick={() => navigateToScanDetailPage(row.uuid)}>
              <PlayCircleFilledIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Verwijderen" placement="top">
            <IconButton onClick={() => setScanToDeleteState(row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  const renderScanHistory = () => {
    if (isScanHistoryLoadingState) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!scanHistoryState.length) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            Er zijn nog geen scans afgerond.
          </TableCell>
        </TableRow>
      );
    }

    return scanHistoryState.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.questionnaire.name}</TableCell>
        <TableCell>
          {row.createdByUser ? `${row.createdByUser.firstName} ${row.createdByUser.lastName}` : 'Anoniem'}
        </TableCell>
        <TableCell>{row.questionnaire.revision}</TableCell>
        <TableCell>
          {moment(row.startDate).format("DD-MM-YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          {moment(row.endDate!).format("DD-MM-YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Tooltip title="Resultaat bekijken" placement="top">
            <Link color="inherit" href={`/scans/${row.uuid}/result`}>
              <IconButton>
                <VisibleIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  const getScanDrafts = async () => {
    try {
      setIsScanDraftsLoadingState(true);

      const response = await api.scansDrafts();

      setScanDraftsState(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsScanDraftsLoadingState(false);
    }
  };

  const getScanHistory = async () => {
    try {
      setIsScanHistoryLoadingState(true);

      const response = await api.scansHistory();

      setScanHistoryState(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsScanHistoryLoadingState(false);
    }
  };

  useEffect(() => {
    getScanDrafts();
    getScanHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isErrorState && !isScanDraftsLoadingState && !isScanHistoryLoadingState) {
    return <Error />;
  }

  return (
    <>
      <ScanAddDialogComponent
        open={isScanAddDialogOpenState}
        handleClose={handleCloseScanAddDialog}
      />
      <ScanDeleteDialogComponent
        open={!!scanToDeleteState}
        scan={scanToDeleteState}
        handleClose={handleCloseScanDeleteDialog}
      />
      <div className={"scan-list-container"}>
        <div className={"scan-list-container__header"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <AssessmentIcon />
              Scans
            </Link>
          </Breadcrumbs>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsScanAddDialogOpenState(true)}
          >
            <AddIcon />
            Nieuwe scan starten
          </Button>
        </div>
        <div className={"scan-list-container__drafts"}>
          <span>Onafgemaakte scans</span>
          <TableContainer
            className={"scan-list-container__drafts__table"}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Vragenlijst</TableCell>
                  <TableCell>Ingevuld door</TableCell>
                  <TableCell>Revisie</TableCell>
                  <TableCell>Start datum</TableCell>
                  <TableCell>Acties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderScanDrafts()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={"scan-list-container__history"}>
          <span>Gemaakte scans</span>
          <TableContainer
            className={"scan-list-container__history__table"}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Vragenlijst</TableCell>
                  <TableCell>Ingevuld door</TableCell>
                  <TableCell>Revisie</TableCell>
                  <TableCell>Start datum</TableCell>
                  <TableCell>Eind datum</TableCell>
                  <TableCell>Acties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderScanHistory()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
