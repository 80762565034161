import React from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  LinearProgress,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Company } from "../../../../../../../models/company/company";
import { CompanyAddDto } from "../../../../../../../models/company/companyAddDto";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface CompanyAddDialogComponentProps {
  open: boolean;
  handleClose: (result?: Company) => void;
}

export function CompanyAddDialogComponent({
  open,
  handleClose,
}: CompanyAddDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState: CompanyAddDto = {
    kvkNumber: "",
    name: "",
    phoneNumber: "",
    email: "",
    branch: "",
    postalCode: "",
    city: "",
  };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [companyAddDtoState, setCompanyAddDtoState] = useState<CompanyAddDto>(
    emptyState
  );

  const handleChange = (companyAddDto: CompanyAddDto) => {
    setCompanyAddDtoState(companyAddDto);
  };

  const handleAdd = async (companyAddDto: CompanyAddDto) => {
    if (
      !companyAddDto.name.length ||
      !companyAddDto.phoneNumber.length ||
      !companyAddDto.email.length ||
      !companyAddDto.branch.length
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.companiesAdd(companyAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
    } finally {
      setCompanyAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>Maak een nieuw bedrijf</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Voor het aanmaken van een bedrijf zijn een naam, telefoonnummer en
          email nodig. Na het aanmaken kunnen er gebruikers toegevoegd worden
          aan het bedrijf.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Naam"
          type="text"
          value={companyAddDtoState.name}
          onChange={(event) =>
            handleChange({ ...companyAddDtoState, name: event.target.value })
          }
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="phoneNumber"
          label="Telefoonnummer"
          type="text"
          value={companyAddDtoState.phoneNumber}
          onChange={(event) =>
            handleChange({
              ...companyAddDtoState,
              phoneNumber: event.target.value,
            })
          }
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="email"
          label="Email"
          type="email"
          value={companyAddDtoState.email}
          onChange={(event) =>
            handleChange({ ...companyAddDtoState, email: event.target.value })
          }
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="branch"
          label="Branch"
          type="text"
          value={companyAddDtoState.branch}
          onChange={(event) =>
            handleChange({ ...companyAddDtoState, branch: event.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          Annuleer
        </Button>
        <Button onClick={() => handleAdd(companyAddDtoState)} color="primary">
          Aanmaken
        </Button>
      </DialogActions>
    </Dialog>
  );
}
