import React, { useState, useEffect } from "react";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { Loader } from "../../../../../common/loader/Loader.component";
import { Error } from "../../../../../common/error/Error.component";
import { QuestionnaireListItemComponent } from "../questionnaire-list-item/QuestionnaireListItem.component";
import {
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Breadcrumbs,
  Link,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./QuestionnaireList.component.sass";
import AddIcon from "@material-ui/icons/Add";
import { QuestionnaireAddDialogComponent } from "../dialog/questionnaire-add-dialog/QuestionnaireAddDialog.component";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { QuestionnaireDeleteDialogComponent } from "../dialog/questionnaire-delete-dialog/QuestionnaireDeleteDialog.component";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useHistory } from "react-router-dom";
import { QuestionnaireNewRevisionDialogComponent } from "../dialog/questionnaire-new-revision-dialog/QuestionnaireNewRevisionDialog.component";
import { QuestionnaireCopyDialogComponent } from "../dialog/questionnaire-copy-dialog/QuestionnaireCopyDialog.component";
import { useApi } from "../../../../../common/providers/Api.provider";

export function QuestionnaireListComponent() {
  const api = useApi();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isErrorState, setIsErrorState] = useState<boolean>(false);
  const [searchTermState, setSearchTermState] = useState<string>("");
  const [
    isQuestionnaireAddDialogOpenState,
    setIsQuestionnaireAddDialogOpenState,
  ] = useState<boolean>(false);
  const [questionnaireToDeleteState, setQuestionnaireToDeleteState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [questionnairesState, setQuestionnairesState] = useState<
    Questionnaire[]
  >([]);
  const [filteredQuestionnairesState, setFilteredQuestionnaireState] = useState<
    Questionnaire[]
  >([]);
  const [
    questionnaireToNewRevisionState,
    setQuestionnaireToNewRevisionState,
  ] = useState<Questionnaire | undefined>(undefined);
  const [questionnaireToCopyState, setQuestionnaireToCopyState] = useState<
    Questionnaire | undefined
  >(undefined);

  const getQuestionnaires = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.questionnaires();

      setQuestionnairesStates(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  const togglePublished = async (
    questionnaireId: number,
    published: boolean
  ) => {
    try {
      const response = await api.questionnairesSetPublished(
        questionnaireId,
        published
      );

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      setQuestionnairesStates(
        questionnairesState.map((q) =>
          q.id === questionnaireId ? { ...q, published } : q
        )
      );
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    }
  };

  const navigateToQuestionnaire = (questionnaireId: number) => {
    setTimeout(() => {
      history.push(`/questionnaires/${questionnaireId}`);
    });
  };

  const checkIfQuestionnaireHasScans = async (questionnaireId: number) => {
    try {
      const response = await api.questionnairesCheck(questionnaireId);

      if (response.data) {
        setQuestionnaireToNewRevisionState(
          questionnairesState.filter((q) => q.id === questionnaireId)[0]
        );
      } else {
        snackbar.enqueueSnackbar(
          "Nieuwe revisie kan niet aangemaakt worden. Er zijn nog geen scans gemaakt op deze vragenlijst",
          getSnackbarOptions({
            variant: "error",
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onChangeSearchTerm = (event: { target: { value: string } }) => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchTermState(searchTerm);

    setFilteredQuestionnaireState(
      questionnairesState.filter(
        (q) =>
          q.id.toString().toLowerCase().includes(searchTerm) ||
          q.name.toLowerCase().includes(searchTerm) ||
          q.description.toLowerCase().includes(searchTerm)
      )
    );
  };

  const handleCloseQuestionnaireAddDialog = (result?: Questionnaire) => {
    if (!!result) {
      setQuestionnairesStates([...questionnairesState, result]);
      setSearchTermState("");
    }

    setIsQuestionnaireAddDialogOpenState(false);
  };

  const handleCloseQuestionnaireDeleteDialog = (result?: number) => {
    if (!!result) {
      setQuestionnairesStates(
        questionnairesState.filter((q) => q.id !== result)
      );
      setSearchTermState("");
    }

    setQuestionnaireToDeleteState(undefined);
  };

  const handleCloseQuestionnaireNewRevisionDialog = (parentId?: number) => {
    setQuestionnaireToNewRevisionState(undefined);

    if (!!parentId) {
      setQuestionnairesState(prev => prev.map(q => {
        if (q.id === parentId) {
          q.revision += 1;
        }

        return q;
      }));

      snackbar.enqueueSnackbar(
        "Revisie opgehoogd",
        getSnackbarOptions({
          variant: "success",
        })
      );
    }
  };

  const handleCloseQuestionnaireCopyDialog = (result?: number) => {
    setQuestionnaireToCopyState(undefined);

    if (!!result) {
      navigateToQuestionnaire(result);
    }
  };

  const setQuestionnairesStates = (questionnaires: Questionnaire[]) => {
    setQuestionnairesState(questionnaires);
    setFilteredQuestionnaireState(questionnaires);
  };

  useEffect(() => {
    getQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader />;
  }

  if (isErrorState) {
    return <Error />;
  }

  return (
    <>
      <QuestionnaireAddDialogComponent
        open={isQuestionnaireAddDialogOpenState}
        handleClose={handleCloseQuestionnaireAddDialog}
      />
      <QuestionnaireDeleteDialogComponent
        open={!!questionnaireToDeleteState}
        questionnaire={questionnaireToDeleteState}
        handleClose={handleCloseQuestionnaireDeleteDialog}
      />
      <QuestionnaireCopyDialogComponent
        open={!!questionnaireToCopyState}
        questionnaire={questionnaireToCopyState}
        handleClose={handleCloseQuestionnaireCopyDialog}
      />
      <QuestionnaireNewRevisionDialogComponent
        open={!!questionnaireToNewRevisionState}
        questionnaire={questionnaireToNewRevisionState}
        handleClose={handleCloseQuestionnaireNewRevisionDialog}
      />
      <div className={"questionnaire-list-container"}>
        <div className={"questionnaire-list-container__information"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <AssignmentIcon />
              Vragenlijsten
            </Link>
          </Breadcrumbs>
          <div
            className={"questionnaire-list-container__information__statistics"}
          >
            <span>Resultaten: {filteredQuestionnairesState.length}</span>
          </div>
        </div>
        <div className={"questionnaire-list-container__search"}>
          <form noValidate autoComplete="off">
            <FormControl
              className={"questionnaire-list-container__search__control"}
            >
              <OutlinedInput
                className={
                  "questionnaire-list-container__search__control__input"
                }
                type="text"
                placeholder="Zoeken..."
                onChange={(event) => onChangeSearchTerm(event)}
                fullWidth={true}
                value={searchTermState}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search for results" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          <Tooltip title="Toevoegen" placement="top">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsQuestionnaireAddDialogOpenState(true)}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Tooltip>
        </div>
        <TableContainer
          className={"questionnaire-list-container__table"}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Revisie</TableCell>
                <TableCell>Acties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredQuestionnairesState.map((row, index) => (
                <QuestionnaireListItemComponent
                  key={index}
                  questionnaire={row}
                  setQuestionnaireToDelete={setQuestionnaireToDeleteState}
                  setQuestionnaireToCopy={setQuestionnaireToCopyState}
                  setQuestionnaireToNewRevision={checkIfQuestionnaireHasScans}
                  togglePublished={togglePublished}
                  navigateToQuestionnaire={navigateToQuestionnaire}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
