import React, { useState, useEffect } from "react";
import { User } from "../../../../../../models/user/user";
import { useSnackbar } from "notistack";
import { UserUpdateDto } from "../../../../../../models/user/userUpdateDto";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { Company } from "../../../../../../models/company/company";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  MenuItem,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { UserRole } from "../../../../../../models/user/userRole";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface UserUpdateDialogComponentProps {
  open: boolean;
  user?: User;
  handleClose: (result?: User) => void;
}

export function UserUpdateDialogComponent({
  open,
  user,
  handleClose,
}: UserUpdateDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [
    userUpdateDtoState,
    setUserUpdateDtoState,
  ] = useState<UserUpdateDto | null>(null);

  const [companiesState, setCompaniesState] = useState<Company[]>([]);

  const getCompanies = async () => {
    try {
      const resposne = await api.companies();

      setCompaniesState(resposne.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!user && !!user.company) {
      setUserUpdateDtoState({ ...user, companyId: user.company.id });
    }
  }, [user]);

  useEffect(() => {
    if (open) {
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (userUpdateDto: UserUpdateDto) => {
    setUserUpdateDtoState(userUpdateDto);
  };

  const handleUpdate = async (userUpdateDto: UserUpdateDto) => {
    if (
      !userUpdateDto.id ||
      !userUpdateDto.firstName.length ||
      !userUpdateDto.lastName.length ||
      !userUpdateDto.email.length ||
      userUpdateDto.companyId === 0
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersUpdate(userUpdateDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setUserUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          "Bewerk een gebruiker"
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!userUpdateDtoState ? (
            <Skeleton variant="rect" />
          ) : (
            "Een gebruiker bewerken kan door de voornaam, achternaam, email, wachtwoord, bedrijf en rol aan te passen."
          )}
        </DialogContentText>
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            InputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            margin="dense"
            id="firstName"
            label="Voornaam"
            type="text"
            value={userUpdateDtoState.firstName}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                firstName: event.target.value,
              })
            }
          />
        )}
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            InputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            margin="dense"
            id="lastName"
            label="Achternaam"
            type="text"
            value={userUpdateDtoState.lastName}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                lastName: event.target.value,
              })
            }
          />
        )}
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            InputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={userUpdateDtoState.email}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                email: event.target.value,
              })
            }
          />
        )}
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            select
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="role"
            label="Rol"
            value={userUpdateDtoState.role}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                role: event.target.value as UserRole,
              })
            }
          >
            <MenuItem value={UserRole.User}>Gebruiker</MenuItem>
            <MenuItem value={UserRole.Researcher}>Onderzoeker</MenuItem>
            <MenuItem value={UserRole.Admin}>Admin</MenuItem>
          </TextField>
        )}
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            select
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="company"
            label="Bedrijf"
            value={userUpdateDtoState.companyId}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                companyId: Number(event.target.value),
              })
            }
          >
            <MenuItem value={0}>Kies een bedrijf</MenuItem>
            {companiesState.map((company, index) => (
              <MenuItem key={index} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>
      <DialogActions>
        {!userUpdateDtoState ? (
          <Skeleton variant="rect" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              Annuleer
            </Button>
            <Button
              onClick={() => handleUpdate(userUpdateDtoState)}
              color="primary"
            >
              Bewerken
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
