import { Scan } from "../models/scan/scan";
import { BarGraphEntry } from "../components/scan/graph/bar-graph/barGraphEntry";
import { Category } from "../models/category/category";
import { Mode } from "./mode";
import { convertModeToText } from "./convertModeToText";

export function formatScanToBarGraphData(
  scan: Scan,
  activeCategory: Category,
  mode: Mode
): BarGraphEntry[] {
  const entries: BarGraphEntry[] = [];
  if (!!scan) {
    const stepResults = scan.scanStepResults.filter(
      (s) => s.step.categoryId === activeCategory.id
    );

    if (stepResults.length) {
      entries.push(
        ...stepResults.map(
          (stepResult) =>
            ({
              step: stepResult.step.name,
              [convertModeToText(mode)]: stepResult.scaledScore,
            } as any)
        )
      );
    }
  }

  return entries;
}
