import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";

export interface TabPanelComponentProps {
  index: number;
  activeIndex: number;
  children: ReactNode;
}

export function TabPanelComponent({
  index,
  activeIndex,
  children,
}: TabPanelComponentProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={activeIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeIndex === index && children}
    </Typography>
  );
}
