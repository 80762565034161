import React, { useState, useEffect } from "react";
import { Filter } from "../filter";
import { Company } from "../../../../../../models/company/company";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@material-ui/core";
import { Loader } from "../../../../../common/loader/Loader.component";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface CompaniesComponentProps {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CompaniesComponent({
  filter,
  setFilter,
  setHasError,
}: CompaniesComponentProps) {
  const api = useApi();
  const [companiesState, setCompaniesState] = useState<Company[]>([]);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    companyId: number
  ) => {
    const companies = filter.companies;

    if (event.target.checked) {
      companies.push(companyId);
    } else {
      const indexOfCompany = companies.indexOf(companyId);

      if (indexOfCompany !== -1) {
        companies.splice(indexOfCompany, 1);
      }
    }

    setFilter((prevState) => ({ ...prevState, companies }));
  };

  const getCompanies = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.companiesFilter();

      setCompaniesState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader loadingText="Bedrijven aan het laden..." />;
  }

  return (
    <div className={"companies-container"}>
      <FormLabel>Bedrijven</FormLabel>
      <FormGroup>
        {companiesState.length ? (
          companiesState.map((company, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color="primary"
                  checked={
                    filter.companies.findIndex((c) => c === company.id) !== -1
                  }
                  onChange={(event) => handleChange(event, company.id)}
                />
              }
              label={company.name}
              labelPlacement="end"
            />
          ))
        ) : (
          <span>Er zijn geen scans gedaan.</span>
        )}
      </FormGroup>
    </div>
  );
}
