import React, { useEffect, useState } from "react";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import {
  SelectData,
  SELECT_TYPE,
} from "../../../../../models/question/selectData";
import { Question } from "../../../../../models/question/question";
import "./SelectType.component.sass";
export interface SelectTypeComponentProps {
  question: Question<SelectData>;
  scanValue: ScanValue<SelectData>;
  setScanValue: (value: ScanValue<SelectData>) => void;
}

export const ALTERNATIVE_OPTION = "ALTERNATIVE";
export const ALTERNATIVE_LABEL = "Anders, namelijk...";
const ALTERNATIVE_PREFIX = "@";

export function SelectTypeComponent({
  question,
  scanValue,
  setScanValue,
}: SelectTypeComponentProps) {
  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;
  const selectData = q.data;

  const [alternativeValue, setAlternativeValue] = useState<string>("");
  const [alternativeOptionEnabled, setAlternativeOptionEnabled] = useState<
    boolean
  >(false);

  useEffect(() => {
    setTimeout(() => {
      if (!!scanValue && !!setScanValue) {
        if (selectData.defaultIndex !== undefined) {
          const value = JSON.stringify([`${selectData.defaultIndex}`]);

          setScanValue({
            ...scanValue,
            value,
            score: Number(selectData.options[selectData.defaultIndex].score),
          });
        }

        let altValue: string | undefined;

        switch (q.data.subType) {
          case SELECT_TYPE.MULTI:
            if (!!scanValue.value) {
              const values: string[] = JSON.parse(scanValue.value);
              altValue = values.find((v) => v.startsWith(ALTERNATIVE_PREFIX));
            } else {
              altValue = undefined;
            }

            break;
          case SELECT_TYPE.DROPDOWN:
          case SELECT_TYPE.RADIO:
          default:
            if (
              !!scanValue.value &&
              scanValue.value.startsWith(ALTERNATIVE_PREFIX)
            ) {
              altValue = scanValue.value;
            }
            break;
        }

        if (altValue) {
          setAlternativeOptionEnabled(true);
          setAlternativeValue(altValue.substr(1));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!alternativeOptionEnabled) {
      setAlternativeValue("");
    }
  }, [alternativeOptionEnabled, setAlternativeValue]);

  const handleAlternativeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    switch (q.data.subType) {
      case SELECT_TYPE.MULTI:
        let values: string[] = !!scanValue.value
          ? JSON.parse(scanValue.value)
          : [];

        const valueIndex = values.indexOf(
          `${ALTERNATIVE_PREFIX}${alternativeValue}`
        );

        if (valueIndex !== -1) {
          values.splice(valueIndex, 1);
        }

        if (value.length > 0) {
          values.push(`${ALTERNATIVE_PREFIX}${value}`);
        }

        setScanValue({ ...scanValue, value: JSON.stringify(values) });
        break;
      case SELECT_TYPE.DROPDOWN:
      case SELECT_TYPE.RADIO:
      default:
        setScanValue({ ...scanValue, value: `${ALTERNATIVE_PREFIX}${value}` });
        break;
    }
    setAlternativeValue(value);
  };

  const handleCheckboxChange = (index: string, checked: boolean) => {
    let value: string[] =
      !!scanValue && !!scanValue.value ? JSON.parse(scanValue.value) : [];

    if (index === `${ALTERNATIVE_OPTION}`) {
      setAlternativeOptionEnabled(checked);
      if (!checked) {
        const valueIndex = value.indexOf(
          `${ALTERNATIVE_PREFIX}${alternativeValue}`
        );

        if (valueIndex !== -1) {
          value.splice(valueIndex, 1);
        }

        setScanValue({
          ...scanValue,
          value: JSON.stringify(value),
        });
      }
      return;
    }

    if (checked) {
      value.push(index);
    } else {
      const valueIndex = value.findIndex((v) => v === index);
      value.splice(valueIndex, 1);
    }

    const score = value.reduce((result, option) => {
      return result + getOptionScore(option);
    }, 0);

    if (!!scanValue && !!setScanValue) {
      setScanValue({
        ...scanValue,
        value: JSON.stringify(value),
        score,
      });
    }
  };

  const getOptionScore = (index: string): number => {
    if (!index) {
      return 0;
    }
    const option = selectData.options[Number(index)];
    return option ? Number(option.score) : 0;
  };

  const renderOption = () => {
    switch (q.data.subType) {
      case SELECT_TYPE.DROPDOWN:
        return (
          <TextField
            fullWidth
            select
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
              native: true,
            }}
            hiddenLabel
            required={q.required}
            value={
              alternativeOptionEnabled
                ? ALTERNATIVE_OPTION
                : !!scanValue
                ? scanValue.value
                : ""
            }
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              const value = event.target.value;
              const score = getOptionScore(value);

              const enableAlternativeOption = value === `${ALTERNATIVE_OPTION}`;
              setAlternativeOptionEnabled(enableAlternativeOption);
              if (enableAlternativeOption) {
                setScanValue({ ...scanValue, value: "", score });
                return;
              }

              if (!!scanValue && !!setScanValue) {
                setScanValue({ ...scanValue, value, score });
              }
            }}
          >
            {selectData.defaultIndex === undefined && (
              <option value="">Kies een optie</option>
            )}
            {(selectData.options || []).map((option, index) => (
              <option key={index} value={index}>
                {option.label}
              </option>
            ))}
            {selectData.enableAlternative && (
              <option value={ALTERNATIVE_OPTION}>{ALTERNATIVE_LABEL}</option>
            )}
          </TextField>
        );
      case SELECT_TYPE.RADIO:
        return (
          <RadioGroup
            value={
              alternativeOptionEnabled
                ? ALTERNATIVE_OPTION
                : !!scanValue
                ? scanValue.value
                : ""
            }
            onChange={(event) => {
              const value = event.target.value;

              const score = getOptionScore(value);

              const enableAlternativeOption = value === `${ALTERNATIVE_OPTION}`;
              setAlternativeOptionEnabled(enableAlternativeOption);
              if (enableAlternativeOption) {
                setScanValue({ ...scanValue, value: "", score });
                return;
              }

              if (!!scanValue && !!setScanValue) {
                setScanValue({ ...scanValue, value, score });
              }
            }}
          >
            {(selectData.options || []).map((option, index) => (
              <FormControlLabel
                key={index}
                value={`${index}`}
                control={<Radio />}
                label={option.label}
              />
            ))}
            {selectData.enableAlternative && (
              <FormControlLabel
                value={ALTERNATIVE_OPTION}
                control={<Radio />}
                label={ALTERNATIVE_LABEL}
              />
            )}
          </RadioGroup>
        );
      case SELECT_TYPE.MULTI:
        return (
          <FormGroup>
            {(selectData.options || []).map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      !!scanValue && scanValue.value
                        ? JSON.parse(scanValue.value).indexOf(`${index}`) !== -1
                        : false
                    }
                    onChange={(event, checked) => {
                      handleCheckboxChange(`${index}`, checked);
                    }}
                  />
                }
                label={option.label}
              />
            ))}
            {selectData.enableAlternative && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alternativeOptionEnabled}
                    onChange={(event, checked) => {
                      handleCheckboxChange(ALTERNATIVE_OPTION, checked);
                    }}
                  />
                }
                label={ALTERNATIVE_LABEL}
              />
            )}
          </FormGroup>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="select-type-container">
      {renderOption()}
      {alternativeOptionEnabled && (
        <TextField
          fullWidth
          required
          variant="outlined"
          color="primary"
          className="select-type-container__form-control"
          value={alternativeValue}
          onChange={handleAlternativeChange}
        />
      )}
    </div>
  );
}
