import React, { useState } from "react";
import { useApi } from "../common/providers/Api.provider";
import { Button } from "@material-ui/core";
import { StepThreePageComponent } from "./pages/step-three-page/StepThreePage.component";
import { useHistory, useLocation } from "react-router-dom";
import "./Register.component.sass";
import { StepOnePageComponent } from "./pages/step-one-page/StepOnePage.component";
import { StepTwoPageComponent } from "./pages/step-two-page/StepTwoPage.component";
import { StepOneState, intialStepOneState, isStepOneStateValid, validateStepOneState } from "./pages/step-one-page/StepOnePageState";
import { initialStepTwoState, isStepTwoStateValid, StepTwoState, validateStepTwoState } from "./pages/step-two-page/StepTwoPageState";
import { initialStepThreeState, StepThreeState, isStepThreeStateValid, validateStepThreeState } from "./pages/step-three-page/StepThreePageState";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../lib/getSnackbarOptions";

export function RegisterComponent() {
  const history = useHistory();
  const api = useApi();
  const snackbar = useSnackbar();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  
  const [selectedIndexState, setSelectedIndexState] = useState<number>(0);
  const [showValidationState, setShowValidationState] = useState<boolean>(false);

  const [stepOneState, setStepOneState] = useState<StepOneState>(intialStepOneState);
  const [stepTwoState, setStepTwoState] = useState<StepTwoState>(initialStepTwoState);
  const [stepThreeState, setStepThreeState] = useState<StepThreeState>(initialStepThreeState);

  const [registerLoadingState, setRegisterLoadingState] = useState<boolean>(false);

  const isLastPage = () => selectedIndexState === 2;

  const renderPreviousButton = () => {
    if (selectedIndexState === 0) {
      return <div className={"spacer"}></div>;
    }

    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setShowValidationState(false);
          const previousSelectedIndex = selectedIndexState - 1;
          setSelectedIndexState(previousSelectedIndex);
        }}
      >
        Vorige
      </Button>
    );
  };

  const renderStep = (selectedIndex: number) => {
    switch (selectedIndex) {
      case 0:
        return (
          <StepOnePageComponent
          {...stepOneState}
          setStepOneState={setStepOneState}
          showValidationState={showValidationState}
          />
        );
      case 1:
        return (
          <StepTwoPageComponent
          {...stepTwoState}
           setStepTwoState={setStepTwoState}
            existingCompany={stepOneState.existingCompany}
            postalCode={!!stepOneState.existingCompany ? stepOneState.existingCompany.postalCode : stepOneState.postalCode!.code.toString()}
            city={!!stepOneState.existingCompany ? stepOneState.existingCompany.city : stepOneState.postalCode!.place}
            branch={stepOneState.existingCompany ? stepOneState.existingCompany.branch : stepOneState.branch}
            showValidationState={showValidationState}
          />
        );
      case 2:
        return (
          <StepThreePageComponent
          {...stepThreeState}
            setStepThreeState={setStepThreeState}
            showValidationState={showValidationState}
          />
        );
    }
  };

  const isValid = (selectedIndex: number) => {
    switch (selectedIndex) {
      case 0:
        setStepOneState(validateStepOneState(stepOneState));
        return isStepOneStateValid(stepOneState);
      case 1:
        setStepTwoState(validateStepTwoState(stepTwoState));
        return isStepTwoStateValid(stepTwoState);
      case 2:
        setStepThreeState(validateStepThreeState(stepThreeState));
       return isStepThreeStateValid(stepThreeState);
    }
  };

  return (
    <div className={"register-container"}>
      <div className={"register-container__content"}>
        <div className={"active-step"}>{renderStep(selectedIndexState)}</div>
        <div className={"button-group"}>
          {renderPreviousButton()}
          <Button
            disabled={registerLoadingState}
            variant="contained"
            color={isLastPage() ? "secondary" : "primary"}
            onClick={async () => {
              if (!isValid(selectedIndexState)) {
                setShowValidationState(true);
                return;
              }
              
              setShowValidationState(false);

              if (isLastPage()) {
                try {
                  setRegisterLoadingState(true);

                  const response = await api.register({
                    scanUuid: queryParams.get('scanUuid') ?? undefined,
                    registerUserDto: {
                      firstName: stepThreeState.firstName,
                      lastName: stepThreeState.lastName,
                      email: stepThreeState.email,
                      password: stepThreeState.password
                    },
                    registerCompanyDto: {
                      kvkNumber: stepTwoState.kvkNumber,
                      name: stepTwoState.name,
                      phoneNumber: stepTwoState.phoneNumber,
                      email: stepTwoState.email,
                      branch: stepTwoState.branch,
                      postalCode: stepTwoState.postalCode,
                      city: stepTwoState.city
                    },
                  });

                  if (response.success) {
                    history.push('/register/complete', {
                      email: stepThreeState.email
                    });
                  } else {
                    throw response.message;
                  }
                } catch (e) {
                  console.log(e);
                  snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
                } finally {
                  setRegisterLoadingState(false);
                }
              } else {
                setSelectedIndexState(selectedIndexState + 1);
              }
            }}
          >
            {isLastPage() ? "Registreren" : "Volgende"}
          </Button>
        </div>
      </div>
    </div>
  );
}
