import React, { ReactNode } from "react";
import { UserRole } from "../../../models/user/userRole";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { User } from "../../../models/user/user";

export interface ProtectedRouteProps {
  loggedInUser: User;
  authorizedRoles: UserRole[];
  unauthorizedRedirectTo: string;
  children: ReactNode;
}

export function ProtectedRoute({
  loggedInUser,
  authorizedRoles,
  unauthorizedRedirectTo,
  children,
  ...routeProps
}: ProtectedRouteProps & RouteProps) {
  return (
    <Route
      {...routeProps}
      render={() =>
        authorizedRoles.some(role => role === loggedInUser.role) ? (
          children
        ) : (
          <Redirect to={unauthorizedRedirectTo} />
        )
      }
    />
  );
}
