import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Error } from "../common/error/Error.component";
import "./Exchange.component.sass";
import { useApi } from "../common/providers/Api.provider";
import { Questionnaire } from "../../models/questionnaire/questionnaire";
import { Loader } from "../common/loader/Loader.component";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../lib/getSnackbarOptions";

export function ExchangeComponent() {
  const { publicCode } = useParams<{ publicCode: string }>();
  const api = useApi();
  const history = useHistory();
  const snackbar = useSnackbar();

  const [questionnaireState, setQuestionnaireState] =
    useState<Questionnaire | null>(null);
  const [isQuestionnaireLoadingState, setIsQuestionnaireLoadingState] =
    useState<boolean>(false);
  const [isScanAddLoadingState, setIsScanAddLoadingState] =
    useState<boolean>(false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [userAcceptedPrivacyPolicy, setUserAcceptedPrivacyPolicy] =
    useState<boolean>(false);
  const [userAcceptedDataProcessing, setUserAcceptedDataProcessing] =
    useState<boolean>(false);

  const getQuestionnaire = async () => {
    if (!publicCode) {
      setHasErrorState(true);
      return;
    }

    try {
      setIsQuestionnaireLoadingState(true);
      const result = await api.questionnairesByPublicCode(publicCode);
      setQuestionnaireState(result.data);
    } catch (e) {
      // NOOP
    } finally {
      setIsQuestionnaireLoadingState(false);
    }
  };

  const addScan = async () => {
    if (!questionnaireState) {
      setHasErrorState(true);
      return;
    }

    try {
      setIsScanAddLoadingState(true);
      const result = await api.scanAdd({
        questionnaireId: questionnaireState.id,
      });
      history.push(`/scans/${result.data.uuid}`);
    } catch (e) {
      snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" }));
    } finally {
      setIsScanAddLoadingState(false);
    }
  };

  useEffect(() => {
    getQuestionnaire();
    // eslint-disable-next-line
  }, []);

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <div className="exchange-container">
      <div className="exchange-container__content">
        <Card className="card">
          <CardContent>
            {isQuestionnaireLoadingState && <Loader />}
            {!!questionnaireState && (
              <>
                <Typography variant="h1">
                  Maak een nieuwe scan aan
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color="secondary"
                      checked={userAcceptedPrivacyPolicy}
                      onChange={(event, checked) => {
                        setUserAcceptedPrivacyPolicy(checked);
                      }}
                    />
                  }
                  label={
                    <span>
                      Ik heb de{" "}
                      <a
                        target="_blank"
                        href="https://brainporteindhoven.com/nl/disclaimer-en-privacy"
                        rel="noopener noreferrer"
                      >
                        privacyverklaring
                      </a>{" "}
                      gelezen en ga hiermee akkoord
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      color="secondary"
                      checked={userAcceptedDataProcessing}
                      onChange={(event, checked) => {
                        setUserAcceptedDataProcessing(checked);
                      }}
                    />
                  }
                  label="Ik ga akkoord met de dataverwerking door Fontys Hogescholen, Holland Expat Center South en Brainport Development"
                />
                <Button
                  className="exchange-container button"
                  onClick={() => addScan()}
                  color="primary"
                  disabled={
                    !userAcceptedDataProcessing || !userAcceptedPrivacyPolicy || isScanAddLoadingState
                  }
                >
                  Scan starten
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
