import React, { useEffect, useState, createElement } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Scan } from "../../../models/scan/scan";
import { Loader } from "../../common/loader/Loader.component";
import { Error } from "../../common/error/Error.component";
import { Paper, Breadcrumbs, Link } from "@material-ui/core";
import { QuestionComponent } from "../../common/question/Question.component";
import { StepperComponent } from "../stepper/Stepper.component";
import { Step } from "../../../models/step/step";
import { Question } from "../../../models/question/question";
import { ScanValue } from "../../../models/scan-value/scanValue";
import { getSnackbarOptions } from "../../../lib/getSnackbarOptions";
import AssessmentIcon from "@material-ui/icons/Assessment";
import "./ScanDetailPage.component.sass";
import { QuestionType } from "../../../models/question/questionType";
import { OpenData } from "../../../models/question/openData";
import { useApi } from "../../common/providers/Api.provider";
import { useUser } from "../../common/providers/User.provider";

export function ScanDetailPageComponent() {
  const api = useApi();
  const history = useHistory();
  const { scanUuid }: any = useParams();
  const snackbar = useSnackbar();
  const [user] = useUser();

  const [hasErrorState, setHasErrorState] = useState<boolean>(false);

  const [isLoadingScanState, setIsLoadingScanState] = useState<boolean>(false);
  const [scanState, setScanState] = useState<Scan | undefined>(undefined);

  const [scanValuesState, setScanValuesState] = useState<ScanValue[]>([]);

  const [isLoadingStepsState, setIsLoadingStepsState] = useState<boolean>(
    false
  );
  const [stepsState, setStepsState] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState<number | undefined>(undefined);

  const [isLoadingQuestionsState, setIsLoadingQuestionsState] = useState<
    boolean
  >(false);
  const [questionsState, setQuestionsState] = useState<Question[]>([]);

  const getScan = async (uuid: string) => {
    try {
      setIsLoadingScanState(true);

      const { data: scan } = await api.scanByUUID(uuid);

      if (scan.endDate) {
        setHasErrorState(true);
        snackbar.enqueueSnackbar(
          "Het is niet toegestaan een afgeronde scan nog eens in te vullen.",
          getSnackbarOptions({
            variant: "error",
          })
        );
        history.push(`/scans/${scanUuid}/result`);
      } else {
        setScanState(scan);
      }
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingScanState(false);
    }
  };

  const getScanValues = async (scanUuid: string) => {
    try {
      const response = await api.scanValuesUUID(scanUuid);

      setScanValuesState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    }
  };

  const getSteps = async (questionnaireId: number) => {
    try {
      setIsLoadingStepsState(true);

      const response = await api.stepsQuestionnaireById(questionnaireId);

      setStepsState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingStepsState(false);
    }
  };

  const getQuestions = async (stepId: number) => {
    try {
      setIsLoadingQuestionsState(true);

      const response = await api.questionsStepById(stepId);

      setScanValuesState([
        ...scanValuesState,
        ...response.data
          .filter(
            (q) =>
              scanValuesState.findIndex((sv) => q.id === sv.questionId) === -1
          )
          .map(
            (q) =>
              ({
                questionId: q.id,
                scanId: scanState!.id,
                value: "",
                score: null,
              } as ScanValue)
          ),
      ]);

      setQuestionsState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingQuestionsState(false);
    }
  };

  const saveScanValues = async () => {
    try {
      const response = await api.scanValuesSave({
        scanId: scanState!.id,
        scanValues: scanValuesState.filter((sv) =>
          questionsState.some((q) => q.id === sv.questionId)
        ),
      });

      setScanValuesState(response.data);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  const finishScan = async () => {
    try {
      const response = await api.scansFinish(scanState!.id, scanState!);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      history.push(`/scans/${scanUuid}/result`);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  const isNextStepDisabled = () => {
    if (activeStep === undefined) {
      return false;
    }

    const step = stepsState[activeStep!];
    const questions = questionsState.filter((q, index) => {
      const previousQuestion = questionsState[index - 1];

      if (previousQuestion && q.type === QuestionType.OPEN) {
        const scanValue = scanValuesState.find(
          (sv) => sv.questionId === previousQuestion.id
        );
        const questionData = q as Question<OpenData>;

        if (
          questionData.data.isTip &&
          (scanValue?.score ?? 0) < (questionData.data.tipMinScore ?? 0)
        ) {
          return false;
        }
      }

      return q.stepId === step.id && q.required;
    });

    return questions.length
      ? scanValuesState
          .filter(
            (sv) => questions.findIndex((q) => q.id === sv.questionId) !== -1
          )
          .some((sv) => !sv.value && !sv.value.length)
      : false;
  };

  const renderHTML = (html?: string) => {
    return createElement("div", {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    });
  };

  const renderContent = () => {
    if (activeStep === undefined) {
      return (
        <Paper
          className={"scan-detail-page-container__inner__content__information"}
        >
          <h1>{scanState?.questionnaire.name}</h1>
          {renderHTML(scanState?.questionnaire.description)}
        </Paper>
      );
    }

    const step = stepsState[activeStep!];

    return (
      <>
        <Paper className={"scan-detail-page-container__inner__content__paper"}>
          <h1>{step.name}</h1>
          {renderHTML(step.description)}
        </Paper>
        <Paper className={"scan-detail-page-container__inner__content__paper"}>
          {isLoadingQuestionsState ? (
            <Loader loadingText="Vragen worden geladen..." />
          ) : (
            questionsState.map((question, index) => {
              const previousQuestion = questionsState[index - 1];
              const nextQuestion = questionsState[index + 1];

              const previousScanValue = scanValuesState.find(
                (sv) =>
                  sv.questionId ===
                  (previousQuestion ? previousQuestion.id : undefined)
              );

              const nextScanValue = scanValuesState.find(
                (sv) =>
                  sv.questionId === (nextQuestion ? nextQuestion.id : undefined)
              );

              const previousScore = previousScanValue
                ? previousScanValue.score
                : 0;

              const scanValue = scanValuesState.find(
                (sv) => sv.questionId === question.id
              );

              return (
                <QuestionComponent
                  key={index}
                  index={index + 1}
                  editingMode={false}
                  question={question}
                  previousScore={previousScore ?? 0}
                  scanValue={scanValue}
                  setScanValue={(value) => {
                    let resetNext = false;
                    if (
                      nextQuestion &&
                      nextQuestion.type === QuestionType.OPEN
                    ) {
                      const questionData = nextQuestion as Question<OpenData>;

                      if (
                        questionData.data.isTip &&
                        (value?.score ?? 0) <
                          (questionData.data.tipMinScore ?? 0) &&
                        !!nextScanValue?.value
                      ) {
                        resetNext = true;
                      }
                    }

                    setScanValuesState(
                      scanValuesState.map((sv) => {
                        if (
                          resetNext &&
                          nextScanValue &&
                          (sv.questionId === nextScanValue.questionId
                            ? value
                            : null)
                        ) {
                          return { ...sv, value: "" };
                        }
                        return sv.questionId === value.questionId ? value : sv;
                      })
                    );
                  }}
                />
              );
            })
          )}
        </Paper>
      </>
    );
  };

  useEffect(() => {
    if (!!scanUuid) {
      getScan(scanUuid);
      getScanValues(scanUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanUuid]);

  useEffect(() => {
    if (!!scanState && scanState.questionnaireId) {
      getSteps(scanState.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanState]);

  useEffect(() => {
    if (activeStep !== undefined && stepsState.length) {
      getQuestions(stepsState[activeStep].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsState, activeStep]);

  if (isLoadingScanState || isLoadingStepsState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <div className={"scan-detail-page-container"}>
        <div className={"scan-detail-page-container__inner"}>
          {!!user && (
            <div className={"scan-detail-page-container__inner__header"}>
              <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
                <Link color="inherit" href="/scans">
                  <AssessmentIcon />
                  Scans
                </Link>
                <Link
                  className={"active"}
                  color="textPrimary"
                  aria-current="page"
                >
                  Scan - {scanState?.questionnaire.name}
                </Link>
              </Breadcrumbs>
            </div>
          )}
          <div className={"scan-detail-page-container__inner__content"}>
            {renderContent()}
          </div>
        </div>
      </div>
      <span className="spacer"></span>
      <StepperComponent
        steps={stepsState.length}
        activeStep={activeStep}
        onChangeActiveStep={async (step) => {
          await saveScanValues();
          setActiveStep(step);
        }}
        onFinish={async () => {
          await saveScanValues();
          await finishScan();
        }}
        isLoading={isLoadingQuestionsState}
        nextStepDisabled={isNextStepDisabled()}
      />
    </>
  );
}
