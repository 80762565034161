import React, { useState, useEffect } from "react";
import {
  Paper,
  Breadcrumbs,
  Link,
  TextField,
  MenuItem,
  ListSubheader,
  Grid,
  Button,
} from "@material-ui/core";
import { Error } from "../../common/error/Error.component";
import { Loader } from "../../common/loader/Loader.component";
import { Scan } from "../../../models/scan/scan";
import { useHistory, useParams } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";
import "./ScanResultPage.component.sass";
import { RadarGraph } from "../graph/radar-graph/RadarGraph.component";
import moment from "moment";
import { zip } from "../../../lib/zip";
import { formatScanToRadarGraphData } from "../../../lib/formatScanToRadarGraphData";
import { CategoryTips } from "./categoryTips";
import { BarGraph } from "../graph/bar-graph/BarGraph.component";
import { formatScanToBarGraphData } from "../../../lib/formatScanToBarGraphData";
import { Category } from "../../../models/category/category";
import { CategoryAdvices } from "./categoryAdvices";
import { CompareWithType } from "./compareWithType";
import { Mode } from "../../../lib/mode";
import { useApi } from "../../common/providers/Api.provider";
import { CategoryResult } from "./categoryResult";
import { CategoryResultComponent } from "../category-result/category-result.component";
import { useUser } from "../../common/providers/User.provider";

export function ScanResultPageComponent() {
  const api = useApi();
  const { scanUuid } = useParams<{ scanUuid: string }>();
  const [user] = useUser();
  const history = useHistory();

  const [isScanLoadingState, setIsScanLoadingState] = useState<boolean>(false);
  const [isGraphLoadingState, setIsGraphLoading] = useState<boolean>(false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [finishedScanState, setFinishedFinishedScanState] = useState<
    Scan | undefined
  >(undefined);
  const [scanToCompareWithState, setScanToCompareWithState] = useState<
    Scan | undefined
  >(undefined);
  const [activeCompareWithTypeState, setActiveCompareWithTypeState] = useState<
    CompareWithType
  >(CompareWithType.NONE);
  const [
    activeCategoryResultState,
    setActiveCategoryResultState,
  ] = useState<CategoryResult | null>(null);

  // Category states
  const [categoriesState, setCategoriesState] = useState<Category[]>([]);
  const [categoryTipsState, setCategoryTipsState] = useState<CategoryTips[]>(
    []
  );
  const [categoryAdvicesState, setCategoryAdvicesState] = useState<
    CategoryAdvices[]
  >([]);
  const [activeCategoryFilterState, setActiveCategoryFilterState] = useState<
    Category | undefined
  >(undefined);

  //  Branch states
  const [branchesState, setBranchesState] = useState<string[]>([]);
  const [activeBranchFilterState, setActiveBranchFilterState] = useState<
    string | undefined
  >(undefined);

  const getScanResult = async (scanUuid: string) => {
    try {
      setIsScanLoadingState(true);

      const response = await api.scansResult(scanUuid);

      const { data: scan } = response;

      setFinishedFinishedScanState(scan);
      setCategoriesState(
        scan.scanCategoryResults.map((s) => {
          return s.category;
        })
      );

      getScanResultTips(scan.uuid);
      getScanResultAdvices(scan.uuid);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsScanLoadingState(false);
    }
  };

  const getPreviousScanResult = async (scanUuid: string) => {
    try {
      const response = await api.scansResultPrevious(scanUuid);

      setScanToCompareWithState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getScanResultTips = async (scanUuid: string) => {
    try {
      const response = await api.scansTips(scanUuid);

      setCategoryTipsState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getScanResultAdvices = async (scanUuid: string) => {
    try {
      const response = await api.scansAdvices(scanUuid);

      setCategoryAdvicesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getBranches = async (scanUuid: string) => {
    try {
      const response = await api.companiesScansBranches(scanUuid);

      setBranchesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getFilteredScansAverageToCompareWith = async (
    scanUuid: string,
    branch: string
  ) => {
    try {
      setIsGraphLoading(true);

      const response = await api.scansAverageBranches(scanUuid, branch);

      setScanToCompareWithState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const renderGraph = () => {
    if (!finishedScanState || isGraphLoadingState) {
      return <Loader loadingText={"Grafiek aan het opbouwen..."} />;
    }

    if (!activeCategoryFilterState) {
      return (
        <RadarGraph
          data={
            !!scanToCompareWithState
              ? zip(
                  formatScanToRadarGraphData(finishedScanState, Mode.FINISHED),
                  formatScanToRadarGraphData(
                    scanToCompareWithState,
                    activeBranchFilterState ? Mode.AVERAGE : Mode.PREVIOUS
                  )
                )
              : formatScanToRadarGraphData(finishedScanState, Mode.FINISHED)
          }
          keys={
            !!scanToCompareWithState
              ? [
                  "Afgeronde scan",
                  activeBranchFilterState
                    ? "Gemiddelde van geselecteerde branch"
                    : "Vorige scan",
                ]
              : ["Afgeronde scan"]
          }
          indexedBy="category"
          maxValue={Math.max.apply(
            Math,
            finishedScanState.scanCategoryResults.map(
              (scr) => scr.category.scale
            )
          )}
        />
      );
    }

    return (
      <BarGraph
        data={
          !!scanToCompareWithState
            ? zip(
                formatScanToBarGraphData(
                  finishedScanState,
                  activeCategoryFilterState,
                  Mode.FINISHED
                ),
                formatScanToBarGraphData(
                  scanToCompareWithState,
                  activeCategoryFilterState,
                  activeBranchFilterState ? Mode.AVERAGE : Mode.PREVIOUS
                )
              )
            : formatScanToBarGraphData(
                finishedScanState,
                activeCategoryFilterState,
                Mode.FINISHED
              )
        }
        keys={
          !!scanToCompareWithState
            ? [
                "Afgeronde scan",
                activeBranchFilterState
                  ? "Gemiddelde van geselecteerde branch"
                  : "Vorige scan",
              ]
            : ["Afgeronde scan"]
        }
        indexedBy="step"
        maxValue={activeCategoryFilterState.scale}
      />
    );
  };

  const renderCategoryTipsAndAdvices = () => {
    const categoriesToRender: CategoryResult[] = [];

    for (const category of categoriesState) {
      const [categoryAdvices] = categoryAdvicesState.filter(
        (ca) => ca.categoryId === category.id
      );
      const [categoryTips] = categoryTipsState.filter(
        (ct) => ct.categoryId === category.id
      );

      if (
        (categoryAdvices && categoryAdvices.advices.length) ||
        (categoryTips && categoryTips.tips.length)
      ) {
        categoriesToRender.push({
          categoryId: category.id,
          categoryName: category.name,
          advices: categoryAdvices ? categoryAdvices.advices : [],
          tips: categoryTips ? categoryTips.tips : [],
        });
      }
    }

    if (!categoriesToRender.length) {
      return;
    }

    return (
      <>
        <Paper className={"scan-result-page-container__paper"}>
          <span>
            Voor onafhankelijk en kosteloos advies over de uitkomsten van de scan 
            en het aantrekken en behouden van internationaal talent kun je vrijblijvend 
            contact opnemen met de adviseurs van Brainport Development en Holland Expat Center South.
          </span>
        </Paper>
        <Paper className={"scan-result-page-container__paper"}>
          <span className={"scan-result-page-container__paper__title red"}>
            Adviezen
          </span>
          <span>
            De adviezen hieronder zijn gebaseerd op resultaten uit onderzoeken
            en de tips komen van uw mede- MKB'ers.
          </span>
        </Paper>
        {categoriesToRender.length > 0 && (
          <div className={"scan-result-page-container__category-results"}>
            {categoriesToRender.map((categoryResult, index) => (
              <CategoryResultComponent
                key={index}
                categoryResult={categoryResult}
                activeCategoryResult={activeCategoryResultState}
                setActiveCategoryResult={setActiveCategoryResultState}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (scanUuid) {
      getScanResult(scanUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanUuid]);

  useEffect(() => {
    if (scanUuid) {
      setActiveCategoryFilterState(undefined);

      switch (activeCompareWithTypeState) {
        case CompareWithType.NONE:
          setActiveBranchFilterState(undefined);
          setScanToCompareWithState(undefined);
          break;
        case CompareWithType.PREVIOUS:
          setActiveBranchFilterState(undefined);
          getPreviousScanResult(scanUuid);
          break;
        case CompareWithType.OTHER:
          setScanToCompareWithState(undefined);
          getBranches(scanUuid);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompareWithTypeState, scanUuid, setActiveBranchFilterState]);

  useEffect(() => {
    if (scanUuid && activeBranchFilterState) {
      getFilteredScansAverageToCompareWith(scanUuid, activeBranchFilterState);
    } else {
      setScanToCompareWithState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranchFilterState, scanUuid]);

  if (isScanLoadingState) {
    return <Loader />;
  }

  if (hasErrorState || !finishedScanState) {
    return <Error />;
  }

  return (
    <div className={"scan-result-page-container page"}>
      {!!user && (
        <div className={"scan-result-page-container__header"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link color="inherit" href="/scans">
              <AssessmentIcon />
              Scans
            </Link>
            <Link color="inherit" href={`/scans/${scanUuid}`}>
              Scan - {finishedScanState.questionnaire.name}
            </Link>
            <Link className={"active"} color="textPrimary" aria-current="page">
              Resultaat
            </Link>
          </Breadcrumbs>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Paper className={"scan-result-page-container__paper graph"}>
            <div
              className={"scan-result-page-container__paper__title-container"}
            >
              <span className={"scan-result-page-container__paper__title"}>
                Scan resultaat -{" "}
                {moment(finishedScanState.endDate!).format("DD/MM/YYYY")}
              </span>
              <div className={"spacer"} />
            </div>
            <div className={"scan-result-page-container__paper__radar"}>
              {renderGraph()}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={"scan-result-page-container__paper filters"}>
            <div
              className={"scan-result-page-container__paper__title-container"}
            >
              <span className={"scan-result-page-container__paper__title"}>
                Vergelijken met
              </span>
            </div>
            <TextField
              select
              fullWidth
              className={"filters__filter"}
              variant="outlined"
              InputLabelProps={{
                hidden: true,
              }}
              value={activeCompareWithTypeState}
              onChange={(event) => {
                setActiveCompareWithTypeState(
                  event.target.value as CompareWithType
                );
              }}
            >
              <MenuItem value={CompareWithType.NONE}>
                <i>Geen</i>
              </MenuItem>
              {!!finishedScanState.createdByUserId && (
                <MenuItem value={CompareWithType.PREVIOUS}>
                  Vorige resultaat
                </MenuItem>
              )}
              <MenuItem value={CompareWithType.OTHER}>
                Op basis van branch
              </MenuItem>
            </TextField>
            <div
              className={
                "scan-result-page-container__paper__title-container wrapper"
              }
            >
              <span className={"scan-result-page-container__paper__title"}>
                Filters
              </span>
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => {
                  setActiveCategoryFilterState(undefined);
                  setActiveBranchFilterState(undefined);
                }}
              >
                Reset
              </Button>
            </div>
            <TextField
              select
              fullWidth
              className={"filters__filter"}
              variant="outlined"
              label="Categorieën"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                displayEmpty: true,
              }}
              value={
                activeCategoryFilterState ? activeCategoryFilterState.id : ""
              }
              onChange={(event) => {
                const value = event.target.value;
                setActiveCategoryFilterState(
                  categoriesState.find((c) => c.id === Number(value))
                );
              }}
            >
              <MenuItem value={""}>
                <i>Geen</i>
              </MenuItem>
              {categoriesState.length && (
                <ListSubheader>Per category</ListSubheader>
              )}
              {categoriesState.map((filter) => (
                <MenuItem key={filter.id} value={filter.id}>
                  {filter.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              fullWidth
              disabled={
                activeCompareWithTypeState === CompareWithType.NONE ||
                activeCompareWithTypeState === CompareWithType.PREVIOUS
              }
              className={"filters__filter"}
              variant="outlined"
              label="Branches"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                displayEmpty: true,
              }}
              value={activeBranchFilterState ? activeBranchFilterState : ""}
              onChange={(event) => {
                const branch = event.target.value;
                setActiveBranchFilterState(branch);
              }}
            >
              <MenuItem value={""}>
                <i>Geen</i>
              </MenuItem>
              {branchesState.length && (
                <ListSubheader>Per branch</ListSubheader>
              )}
              {branchesState.map((branch, index) => (
                <MenuItem key={index} value={branch}>
                  {branch}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Grid>
      </Grid>
      {renderCategoryTipsAndAdvices()}
      <Paper className={"scan-result-page-container__paper"}>
        <span style={{ display: 'block', marginBottom: '20px' }}>
          {!!user ? "De scan is afgerond en staat in je overzicht." : "De scan is afgerond, mocht je de scan terug willen zien log dan in of registreer als je nog geen account hebt."}
        </span>
        {!!user ? (
          <>
            <Button
              disableElevation
              color="secondary"
              variant="contained"
              onClick={() => {
                history.push('/scans');
              }}
            >
              Terug naar scans overzicht
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{ marginRight: "20px" }}
              disableElevation
              color="secondary"
              variant="contained"
              onClick={() => {
                history.push(`/register?scanUuid=${scanUuid}`);
              }}
            >
              Register
            </Button>
            <Button
              disableElevation
              color="secondary"
              variant="contained"
              onClick={() => {
                history.push(`/login?scanUuid=${scanUuid}`);
              }}
            >
              Login
            </Button>
          </>
        )}
      </Paper>
    </div>
  );
}
