import React from "react";
import { Button, LinearProgress } from "@material-ui/core";
import "./Stepper.component.sass";

export interface StepperComponentProps {
  steps?: number;
  activeStep?: number;
  onChangeActiveStep: (stepIndex: number) => void;
  onFinish: () => void;

  isLoading: boolean;
  nextStepDisabled: boolean;
}

export function StepperComponent({
  steps,
  activeStep,
  onChangeActiveStep,
  onFinish,
  isLoading,
  nextStepDisabled,
}: StepperComponentProps) {
  const isActiveCategorySet = () => activeStep !== undefined;

  const isLastStep = () =>
    isActiveCategorySet() && !!steps ? activeStep! + 1 === steps : false;

  const normalise = (value: number) =>
    isActiveCategorySet() && !!steps ? (value * 100) / steps : 0;

  const previousCategory = () => {
    if (!!activeStep) {
      onChangeActiveStep(activeStep - 1);
    }
  };

  const nextCategory = () => {
    if (activeStep === undefined) {
      // Initialize active category and start scan
      onChangeActiveStep(0);
    } else if (isLastStep()) {
      // Finish the scan
      onFinish();
    } else {
      // Set next category as active
      onChangeActiveStep(activeStep + 1);
    }
  };

  const renderPreviousStepButton = () => {
    if (!isActiveCategorySet()) {
      return <span className="spacer" />;
    }

    return (
      <Button
        disabled={activeStep! === 0 || isLoading}
        variant="contained"
        color="primary"
        onClick={() => previousCategory()}
      >
        Vorige
      </Button>
    );
  };

  const renderNextStepButton = () => {
    let text = "Start";

    if (isActiveCategorySet()) {
      if (isLastStep()) {
        text = "Afronden";
      } else {
        text = "Volgende";
      }
    }

    return (
      <Button
        disabled={nextStepDisabled || isLoading}
        variant="contained"
        color="secondary"
        onClick={() => nextCategory()}
      >
        {text}
      </Button>
    );
  };

  return (
    <div className="stepper">
      <div className="stepper__inner">
        {renderPreviousStepButton()}
        {isActiveCategorySet() && (
          <div className={"stepper__inner__progress"}>
            <span>{Math.ceil(normalise(activeStep!))}% compleet</span>
            <LinearProgress
              className={"stepper__inner__progress__bar"}
              value={normalise(activeStep!)}
              variant="determinate"
            />
          </div>
        )}
        {renderNextStepButton()}
      </div>
    </div>
  );
}
