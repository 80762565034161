import { OptionData } from "./optionData";

export enum SELECT_TYPE {
  RADIO = "RADIO",
  DROPDOWN = "DROPDOWN",
  MULTI = "MULTI"
}
export interface SelectData {
  options: OptionData[];
  defaultIndex: number | undefined;
  subType: SELECT_TYPE;
  enableAlternative: boolean;
}
