import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Company } from "../../../../../../../models/company/company";
import { CompanyUpdateDto } from "../../../../../../../models/company/companyUpdateDto";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface CompanyUpdateDialogComponentProps {
  open: boolean;
  company?: Company;
  handleClose: (result?: Company) => void;
}

export function CompanyUpdateDialogComponent({
  open,
  company,
  handleClose,
}: CompanyUpdateDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [
    companyUpdateDtoState,
    setCompanyUpdateDtoState,
  ] = useState<CompanyUpdateDto | null>(null);

  useEffect(() => {
    if (!!company) {
      setCompanyUpdateDtoState(company);
    }
  }, [company]);

  const handleChange = (companyUpdateDto: CompanyUpdateDto) => {
    setCompanyUpdateDtoState(companyUpdateDto);
  };

  const handleUpdate = async (companyUpdateDto: CompanyUpdateDto) => {
    if (
      !companyUpdateDto.id ||
      !companyUpdateDto.name.length ||
      !companyUpdateDto.phoneNumber.length ||
      !companyUpdateDto.email.length ||
      !companyUpdateDto.branch.length
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.companiesUpdate(companyUpdateDto);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setCompanyUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!companyUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          `Bewerk ${companyUpdateDtoState.name}`
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!companyUpdateDtoState ? (
            <Skeleton variant="rect" />
          ) : (
            "Voor het bewerken van een bedrijf zijn een naam, telefoonnummer en email nodig."
          )}
        </DialogContentText>
        {!companyUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Naam"
            type="text"
            value={companyUpdateDtoState.name}
            onChange={(event) =>
              handleChange({
                ...companyUpdateDtoState,
                name: event.target.value,
              })
            }
          />
        )}
        {!companyUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="phoneNumber"
            label="Telefoonnummer"
            value={companyUpdateDtoState.phoneNumber}
            onChange={(event) =>
              handleChange({
                ...companyUpdateDtoState,
                phoneNumber: event.target.value,
              })
            }
          />
        )}
        {!companyUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="email"
            label="Email"
            value={companyUpdateDtoState.email}
            onChange={(event) =>
              handleChange({
                ...companyUpdateDtoState,
                email: event.target.value,
              })
            }
          />
        )}
        {!companyUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="branch"
            label="Branch"
            value={companyUpdateDtoState.branch}
            onChange={(event) =>
              handleChange({
                ...companyUpdateDtoState,
                branch: event.target.value,
              })
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!companyUpdateDtoState ? (
          <Skeleton variant="rect" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              Annuleer
            </Button>
            <Button
              onClick={() => handleUpdate(companyUpdateDtoState)}
              color="primary"
            >
              Bewerken
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
