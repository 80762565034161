import { QuestionType } from "../models/question/questionType";
import { SelectData, SELECT_TYPE } from "../models/question/selectData";

export function getDefaultQuestionData(questionType: QuestionType): any {
  switch (questionType) {
    case QuestionType.SELECT:
      return {
        subType: SELECT_TYPE.DROPDOWN,
        enableAlternative: false
      } as SelectData;
    default:
      return {};
  }
}
