import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Switch } from "react-router-dom";
import { SideMenuComponent } from "./side-menu/SideMenu.component";
import { QuestionnaireListPageComponent } from "./pages/questionnaire/questionnaire-list-page/QuestionnaireListPage.component";
import { QuestionnaireDetailPageComponent } from "./pages/questionnaire/questionnaire-detail-page/QuestionnaireDetailPage.component";
import { useUser } from "../common/providers/User.provider";
import { ProtectedRoute } from "../common/protected-route/ProtectedRoute.component";
import { UserRole } from "../../models/user/userRole";
import { ScanListPageComponent } from "./pages/scan/scan-list-page/ScanListPage.component";
import { CompanyListPageComponent } from "./pages/company/company-list-page/CompanyListPage.component";
import { UserListPageComponent } from "./pages/user/user-list-page/UserListPage.component";
import { DataListPageComponent } from "./pages/data/data-list-page/DataListPage.component";
import { HomePageComponent } from "./pages/home/HomePage.component";
import { ContactPageComponent } from "./pages/contact/ContactPage.component";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      height: "100vh",
      boxSizing: "border-box",
      overflow: "auto",
    },
    menuContainer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    headerContainer: {
      height: "65px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      fontWeight: 500,
      color: "#f44336",
      backgroundColor: "#fff",
    },
    contentContainer: {
      height: "calc(100% - 65px)",
      display: "flex",
      flexDirection: "column",
      marginTop: "65px",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      overflow: "auto",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: { ...theme.mixins.toolbar },
    drawerPaper: {
      width: drawerWidth,
    },
    partners: {
      flexDirection: "row",
    },
    logo: {
      display: "inline",
      height: 45,
      width: "auto",
      marginRight: 20,
    }
  })
);

export function DashboardComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const [user] = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.headerContainer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            International Talent Scan
          </Typography>
          <span className="spacer"></span>
          <div className={classes.partners}>
            <a target="_blank" rel="noopener noreferrer" href="https://brainporteindhoven.com/">
              <img alt="brainport" className={classes.logo} src="/img/bp_logo.jpg"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.hollandexpatcenter.com/">
              <img alt="hollandexpatcenter" className={classes.logo} src="/img/HECS_logo.png"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://fontys.nl/">
             <img alt="fontys" className={classes.logo} src="/img/fontys_logo.png"/>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.menuContainer} aria-label="mailbox folders">
        <Hidden smUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <SideMenuComponent />
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            <SideMenuComponent />
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.contentContainer}>
        <Switch>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[
              UserRole.Admin,
              UserRole.Researcher,
              UserRole.User,
            ]}
            unauthorizedRedirectTo="/login"
            exact
            path="/"
          >
            <HomePageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin, UserRole.Researcher, UserRole.User]}
            unauthorizedRedirectTo="/"
            exact
            path="/contact"
          >
            <ContactPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin, UserRole.Researcher]}
            unauthorizedRedirectTo="/"
            exact
            path="/questionnaires"
          >
            <QuestionnaireListPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin]}
            unauthorizedRedirectTo="/"
            path="/questionnaires/:questionnaireId"
          >
            <QuestionnaireDetailPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[
              UserRole.Admin,
              UserRole.Researcher,
              UserRole.User,
            ]}
            unauthorizedRedirectTo="/"
            exact
            path="/scans"
          >
            <ScanListPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin]}
            unauthorizedRedirectTo="/"
            exact
            path="/users"
          >
            <UserListPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin]}
            unauthorizedRedirectTo="/"
            exact
            path="/companies"
          >
            <CompanyListPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            loggedInUser={user}
            authorizedRoles={[UserRole.Admin, UserRole.Researcher]}
            unauthorizedRedirectTo="/"
            exact
            path="/data"
          >
            <DataListPageComponent />
          </ProtectedRoute>
        </Switch>
      </div>
    </div>
  );
}
