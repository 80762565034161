import React from "react";
import { Company } from "../../../../../../models/company/company";
import { TableRow, TableCell, Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export interface CompanyListItemComponentProps {
  company: Company;
  setCompanyToUpdate: React.Dispatch<React.SetStateAction<Company | undefined>>;
  setCompanyToDelete: React.Dispatch<React.SetStateAction<Company | undefined>>;
}

export function CompanyListItemComponent({
  company,
  setCompanyToUpdate,
  setCompanyToDelete,
}: CompanyListItemComponentProps) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {company.id}
      </TableCell>
      <TableCell>{company.name}</TableCell>
      <TableCell>{company.phoneNumber}</TableCell>
      <TableCell>{company.email}</TableCell>
      <TableCell className={"company-list-container__table__branch"}>
        {company.branch}
      </TableCell>
      <TableCell>
        <Tooltip title="Bewerken" placement="top">
          <IconButton onClick={() => setCompanyToUpdate(company)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Verwijderen" placement="top">
          <IconButton onClick={() => setCompanyToDelete(company)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
