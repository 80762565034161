import React from "react";
import { User } from "../../../../../models/user/user";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { convertUserRoleToText } from "../../../../../lib/convertUserRoleToText";
import { useUser } from "../../../../common/providers/User.provider";
import SettingsIcon from "@material-ui/icons/Settings";

export interface UserListItemComponentProps {
  user: User;

  setUserToUpdate: React.Dispatch<React.SetStateAction<User | undefined>>;
  setUserToDelete: React.Dispatch<React.SetStateAction<User | undefined>>;
  setUserToChangePassword: React.Dispatch<
    React.SetStateAction<User | undefined>
  >;
}

export function UserListItemComponent({
  user,
  setUserToUpdate,
  setUserToDelete,
  setUserToChangePassword,
}: UserListItemComponentProps) {
  const [authenticatedUser] = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isAuthenticatedUser = () => authenticatedUser.id === user.id;

  const handleSettingsMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="user">
        {user.id}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <span
          style={{
            textDecoration: isAuthenticatedUser() ? "underline" : "none",
          }}
        >
          {user.firstName} {user.lastName}
        </span>
      </TableCell>
      <TableCell>{user.company?.name}</TableCell>
      <TableCell>{convertUserRoleToText(user.role)}</TableCell>
      <TableCell>
        <IconButton onClick={handleSettingsMenuClick}>
          <SettingsIcon />
        </IconButton>
        <Menu
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleSettingsMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleSettingsMenuClose();
              setUserToChangePassword(user);
            }}
          >
            Wachtwoord wijzigen
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSettingsMenuClose();
              setUserToUpdate(user);
            }}
          >
            Gebruiker bewerken
          </MenuItem>
          {!isAuthenticatedUser() && (
            <MenuItem
              onClick={() => {
                handleSettingsMenuClose();
                setUserToDelete(user);
              }}
            >
              Gebruiker verwijderen
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
}
