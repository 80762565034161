import React from "react";
import { Paper, Typography } from "@material-ui/core";
import "./HomePage.component.sass";

export function HomePageComponent() {
  return (
    <Paper className={"home-page-container"}>
      <Typography variant="h4" gutterBottom>
        Welkom bij de International Talent Scan!
      </Typography>
      <Typography variant="body1" gutterBottom paragraph>
        Deze totaalscan geeft inzicht in de krachten en aandachtspunten van je organisatie op het gebied van het aantrekken en behouden van internationale kenniswerkers. Maar dat is niet alles, met deze scan kun je ook zien hoe jouw organisatie leert en groeit op dit gebied. Haal inspiratie uit de tips van jouw collega-MKB’ers en inspireer andere MKB’ers met tips. Vergelijk jouw bedrijf met andere bedrijven uit de branche. Krijg gerichte adviezen op basis van je resultaten en kom in contact met de adviseurs van Brainport Development en Holland Expat Center South. Op deze manier maken we de Brainport Eindhoven regio en de provincie Noord-Brabant nog aantrekkelijker voor de internationale kenniswerkers.
      </Typography>
      <Typography variant="body1" gutterBottom paragraph>
        Veel plezier met het invullen en we hopen dat je veel nieuwe inzichten en inspiratie opdoet.
      </Typography>
      <Typography variant="body2" paragraph gutterBottom>
              Neem voor meer informatie contact op met Brainport Development en Holland Expat Center South via <a href="mailto:talent@brainporteindhoven.com">talent@brainporteindhoven.com</a>
      </Typography>
    </Paper>
  );
}
