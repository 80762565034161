import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { User } from "../../../models/user/user";
import { useApi } from "./Api.provider";

const UserStateContext = createContext<
  [User, Dispatch<SetStateAction<User | null>>]
>([null as any, () => {}]);

export const UserProvider: React.SFC = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null as any);
  const api = useApi();

  useEffect(() => {
    api
      .usersMe()
      .then((response) => {
        setUser(response.data);
      })
      .finally(() => {
        setInitialized(true);
      });
  }, [api]);

  return (
    <UserStateContext.Provider value={[user as any, setUser]}>
      {initialized && children}
    </UserStateContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserStateContext);
};
