import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { DashboardComponent } from "./dashboard/Dashboard.component";
import { LoginComponent } from "./login/Login.component";
import { useUser } from "./common/providers/User.provider";
import moment from "moment";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { RegisterComponent } from "./register/Register.component";
import { RegisterActivateComponent } from "./register-activate/RegisterActivate.component";
import { RegisterCompleteComponent } from "./register-complete/RegisterComplete.component";
import { ExchangeComponent } from "./exchange/Exchange.component";
import { ScanDetailPageComponent } from "./scan/scan-detail-page/ScanDetailPage.component";
import { ScanResultPageComponent } from "./scan/scan-result-page/ScanResultPage.component";

moment.locale("nl");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#232323",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

export default function RootComponent() {
  const [user] = useUser();
  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login">
            {!!user ? <Redirect to="/" /> : <LoginComponent />}
          </Route>
          <Route exact={true} path="/register">
            <RegisterComponent />
          </Route>
          <Route path="/register/complete">
            <RegisterCompleteComponent />
          </Route>
          <Route path="/register/activate">
            <RegisterActivateComponent />
          </Route>
          <Route path="/exchange/:publicCode">
            <ExchangeComponent />
          </Route>
          <Route exact path="/scans/:scanUuid">
            <ScanDetailPageComponent />
          </Route>
          <Route exact path="/scans/:scanUuid/result">
            <ScanResultPageComponent />
          </Route>
          <Route path="/">
            {!!user ? <DashboardComponent /> : <Redirect to="/login" />}
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
