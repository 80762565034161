import React, { useRef, useEffect, useState } from "react";
import { Question } from "../../../../../../models/question/question";
import { QuestionType } from "../../../../../../models/question/questionType";
import {
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  IconButton,
  Fab,
  Tooltip,
} from "@material-ui/core";
import "./EditableQuestion.component.sass";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { convertQuestionTypeToText } from "../../../../../../lib/convertQuestionTypeToText";
import { PolarInputComponent } from "./input/polar-input/PolarInput.component";
import { OpenInputComponent } from "./input/open-input/OpenInput.component";
import { LikertScaleInputComponent } from "./input/likert-scale-input/LikertScaleInput.component";
import { SelectInputComponent } from "./input/select-input/SelectInput.component";
import { QuestionComponent } from "../../../../../common/question/Question.component";
import { SelectData } from "../../../../../../models/question/selectData";
import { LikertScaleData } from "../../../../../../models/question/likertScaleData";
import { OpenData } from "../../../../../../models/question/openData";
import { PolarData } from "../../../../../../models/question/polarData";
import { ScanValue } from "../../../../../../models/scan-value/scanValue";
import { QuestionData } from "../../../../../../models/question/questionData";
import { Loader } from "../../../../../common/loader/Loader.component";

export interface EditableQuestionComponentProps {
  index: number;
  value: Question;
  active: boolean;
  isUpwardDisabled: boolean;
  isDownwardDisabled: boolean;
  setActive: (value: boolean) => void;
  setValue: (value: Question) => void;
  removeValue: () => void;
  sendValueUpward: () => void;
  sendValueDownward: () => void;
  scanValue: ScanValue;
  setScanValue: (value: ScanValue) => void;
  copyQuestion: (value: Question) => void;
}

export function EditableQuestionComponent({
  index,
  value,
  active,
  setActive,
  setValue,
  removeValue,
  sendValueUpward,
  sendValueDownward,
  isUpwardDisabled,
  isDownwardDisabled,
  scanValue,
  setScanValue,
  copyQuestion,
}: EditableQuestionComponentProps) {
  const node = useRef();

  const [onCloseState, setOnCloseState] = useState<boolean>(false);
  const [innerState, setInnerState] = useState<Question<QuestionData> | null>(
    null
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (active && !(node.current as any).contains(event.target)) {
      setOnCloseState(true);
    }
  };

  useEffect(() => {
    setInnerState(value);
  }, [value]);

  useEffect(() => {
    if (onCloseState && innerState) {
      setValue(innerState);
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseState, innerState]);

  useEffect(() => {
    if (active) {
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        document.removeEventListener("mouseup", handleClickOutside);
      };
    } else {
      setOnCloseState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const renderQuestion = () => {
    if (!active) {
      return (
        <QuestionComponent
          editingMode={true}
          scanValue={scanValue}
          setScanValue={setScanValue}
          index={index + 1}
          question={innerState!}
        />
      );
    }

    switch (value.type) {
      case QuestionType.LIKERT_SCALE:
        return (
          <LikertScaleInputComponent
            value={innerState as Question<LikertScaleData>}
            setValue={setInnerState}
          />
        );
      case QuestionType.OPEN:
        return (
          <OpenInputComponent
            value={innerState as Question<OpenData>}
            setValue={setInnerState}
          />
        );
      case QuestionType.SELECT:
        return (
          <SelectInputComponent
            value={innerState as Question<SelectData>}
            setValue={setInnerState}
          />
        );
      case QuestionType.POLAR:
        return (
          <PolarInputComponent
            value={innerState as Question<PolarData>}
            setValue={setInnerState}
          />
        );
      default:
        throw Error("Question type not found");
    }
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerState({ ...innerState!, required: event.target.checked });
  };

  const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerState({ ...innerState!, label: event.target.value });
  };

  const handleChangeHint = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerState({ ...innerState!, hint: event.target.value });
  };

  if (!innerState) {
    return <Loader />;
  }

  return (
    <div
      ref={node as any}
      className={`editable-question-container ${active ? "active" : ""}`}
    >
      {active && (
        <>
          <div className={"editable-question-container__header"}>
            <Typography className={"editable-question-container__header__type"}>
              Type vraag:{" "}
              <span>{convertQuestionTypeToText(innerState.type)}</span>
            </Typography>
            <div className={"editable-question-container__header__actions"}>
              {isUpwardDisabled ? (
                <IconButton disabled={true} onClick={sendValueUpward}>
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <Tooltip title="Verplaats naar boven" placement="top">
                  <IconButton disabled={false} onClick={sendValueUpward}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isDownwardDisabled ? (
                <IconButton disabled={true} onClick={sendValueDownward}>
                  <ArrowDownwardIcon />
                </IconButton>
              ) : (
                <Tooltip title="Verplaats naar onder" placement="top">
                  <IconButton disabled={false} onClick={sendValueDownward}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Kopieer vraag" placement="top">
                <IconButton onClick={() => copyQuestion(value)}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Verwijder" placement="top">
                <IconButton onClick={removeValue}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Vraag"
            color="primary"
            className={"form-control"}
            value={innerState.label}
            onChange={handleChangeLabel}
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Hint"
            color="primary"
            className={"form-control"}
            value={innerState.hint || ""}
            onChange={handleChangeHint}
          />
          {renderQuestion()}
          <div className={"editable-question-container__required"}>
            <FormControlLabel
              control={
                <Switch
                  required
                  checked={innerState.required}
                  onChange={handleChangeChecked}
                  value={innerState.required}
                />
              }
              label="Verplicht"
            />
          </div>
        </>
      )}
      {!active && renderQuestion()}
      <div className={"editable-question-container__action"}>
        <Fab size="small" color="secondary" onClick={(e) => setActive(true)}>
          <EditIcon />
        </Fab>
      </div>
    </div>
  );
}
