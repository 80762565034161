import { Mode } from "./mode";

export function convertModeToText(mode: Mode) {
  let text: string;

  switch(mode) {
    case Mode.AVERAGE:
      text = "Gemiddelde van geselecteerde branch";
      break;
    case Mode.FINISHED:
      text = "Afgeronde scan";
      break;
    case Mode.PREVIOUS:
      text = "Vorige scan";
      break;
    default:
      throw new Error('Cannot find mode');
  }

  return text;
}