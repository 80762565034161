import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { CategoryResult } from "../scan-result-page/categoryResult";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface CategoryResultComponentProps {
  categoryResult: CategoryResult;

  activeCategoryResult: CategoryResult | null;
  setActiveCategoryResult: React.Dispatch<
    React.SetStateAction<CategoryResult | null>
  >;
}

export function CategoryResultComponent({
  categoryResult,
  activeCategoryResult,
  setActiveCategoryResult,
}: CategoryResultComponentProps) {
  const handleChangeExpansionPanel = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    if (
      !!activeCategoryResult &&
      activeCategoryResult.categoryId === categoryResult.categoryId
    ) {
      setActiveCategoryResult(null);
    } else {
      setActiveCategoryResult(categoryResult);
    }
  };

  return (
    <ExpansionPanel
      className={"category-container__expansion-panel"}
      expanded={
        !!activeCategoryResult &&
        activeCategoryResult.categoryId === categoryResult.categoryId
      }
      onChange={handleChangeExpansionPanel}
    >
      <ExpansionPanelSummary
        className={"category-container__expansion-panel"}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography component="span">{categoryResult.categoryName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {categoryResult.advices.length > 0 && (
          <div className={"category-container__advices"}>
            <span className={"category-container__sub-title"}>Advies</span>
            {categoryResult.advices.map((advice, adviceIndex) => (
              <div
                key={adviceIndex}
                dangerouslySetInnerHTML={{ __html: advice }}
              />
            ))}
          </div>
        )}
        {categoryResult.tips.length > 0 && (
          <div className={"category-container__tips"}>
            <span className={"category-container__sub-title"}>Tips</span>
            <ul>
              {categoryResult.tips.map((tip, tipIndex) => (
                <li key={tipIndex}>{tip}</li>
              ))}
            </ul>
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
