import React from "react";
import { Tooltip } from "@material-ui/core";
import { ScanValue } from "../../../models/scan-value/scanValue";
import { QuestionType } from "../../../models/question/questionType";
import { SelectTypeComponent } from "./type/select-type/SelectType.component";
import { OpenTypeComponent } from "./type/open-type/OpenType.component";
import { PolarTypeComponent } from "./type/polar-type/PolarType.component";
import { LikertScaleTypeComponent } from "./type/likert-scale-type/LikertScaleType.component";
import { Question } from "../../../models/question/question";
import InfoIcon from "@material-ui/icons/Info";
import "./Question.component.sass";
import { SelectData } from "../../../models/question/selectData";
import { OpenData } from "../../../models/question/openData";

export interface QuestionComponentProps {
  index: number;
  editingMode: boolean;
  question?: Question;
  scanValue?: ScanValue;
  previousScore?: number;
  setScanValue: (value: ScanValue) => void;
}

export function QuestionComponent({
  index,
  editingMode,
  question,
  previousScore,
  scanValue,
  setScanValue,
}: QuestionComponentProps) {
  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;

  const renderHint = () => {
    return (
      <Tooltip title={q.hint} placement="top">
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    );
  };

  if (q.type === QuestionType.OPEN && !editingMode) {
    const questionData = question as Question<OpenData>;
    if (
      questionData.data.isTip &&
      (previousScore ?? 0) < (questionData.data.tipMinScore ?? 0)
    ) {
      return <></>;
    }
  }

  const renderQuestion = () => {
    switch (q.type) {
      case QuestionType.LIKERT_SCALE:
        return (
          <LikertScaleTypeComponent
            question={question}
            scanValue={scanValue}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.OPEN:
        return (
          <OpenTypeComponent
            question={question as Question<OpenData>}
            scanValue={scanValue as ScanValue<OpenData>}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.SELECT:
        return (
          <SelectTypeComponent
            question={question as Question<SelectData>}
            scanValue={scanValue as ScanValue<SelectData>}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.POLAR:
        return (
          <PolarTypeComponent
            question={question}
            scanValue={scanValue}
            setScanValue={setScanValue}
          />
        );
      default:
        throw Error("Question type not found");
    }
  };

  return (
    <div className={"question-container"}>
      <div className={"question-container__header"}>
        <span className={"question-container__header__title"}>
          {index}. {q.label}
          {q.required && "*"}
        </span>
        {!!q.hint && renderHint()}
      </div>
      {renderQuestion()}
    </div>
  );
}
