import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { User } from "../../../../../../models/user/user";
import { UserRole } from "../../../../../../models/user/userRole";
import { UserAddDto } from "../../../../../../models/user/userAddDto";
import { Company } from "../../../../../../models/company/company";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface UserAddDialogComponentProps {
  open: boolean;
  handleClose: (result?: User) => void;
}

export function UserAddDialogComponent({
  open,
  handleClose,
}: UserAddDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: UserRole.User,
    companyId: 0,
  };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [userAddDtoState, setUserAddDtoState] = useState<UserAddDto>(
    emptyState
  );

  const [companiesState, setCompaniesState] = useState<Company[]>([]);

  const getCompanies = async () => {
    try {
      const response = await api.companies();

      setCompaniesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (userAddDto: UserAddDto) => {
    setUserAddDtoState(userAddDto);
  };

  const handleAdd = async (userAddDto: UserAddDto) => {
    if (
      !userAddDto.firstName.length ||
      !userAddDto.lastName.length ||
      !userAddDto.email.length ||
      !userAddDto.password.length ||
      userAddDto.companyId === 0
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersAdd(userAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as string,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setUserAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open) {
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>Maak een nieuwe gebruiker aan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Voor het aanmaken van een gebruiker zijn een voor- en achternaam
          nodig, email, wachtwoord, bedrijf en rol nodig. Na het aanmaken kan
          deze gebruiker inloggen met de ingevulde email en wachtwoord.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="firstName"
          label="Voornaam"
          type="text"
          value={userAddDtoState.firstName}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              firstName: event.target.value,
            })
          }
        />
        <TextField
          autoFocus
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="lastName"
          label="Achternaam"
          type="text"
          value={userAddDtoState.lastName}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              lastName: event.target.value,
            })
          }
        />
        <TextField
          autoFocus
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="email"
          label="Email"
          type="email"
          value={userAddDtoState.email}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              email: event.target.value,
            })
          }
        />
        <TextField
          autoFocus
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="password"
          label="Wachtwoord"
          type="password"
          value={userAddDtoState.password}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              password: event.target.value,
            })
          }
        />
        <TextField
          select
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="role"
          label="Rol"
          value={userAddDtoState.role}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              role: event.target.value as UserRole,
            })
          }
        >
          <MenuItem value={UserRole.User}>Gebruiker</MenuItem>
          <MenuItem value={UserRole.Researcher}>Onderzoeker</MenuItem>
          <MenuItem value={UserRole.Admin}>Admin</MenuItem>
        </TextField>
        <TextField
          select
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="company"
          label="Bedrijf"
          value={userAddDtoState.companyId}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              companyId: Number(event.target.value),
            })
          }
        >
          <MenuItem value={0}>Kies een bedrijf</MenuItem>
          {companiesState.map((company, index) => (
            <MenuItem key={index} value={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          Annuleer
        </Button>
        <Button onClick={() => handleAdd(userAddDtoState)} color="primary">
          Aanmaken
        </Button>
      </DialogActions>
    </Dialog>
  );
}
