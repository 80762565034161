import { Question } from "../question/question";
import { QuestionData } from "../question/questionData";

export class ScanValue<T = QuestionData> {
  id!: number;
  questionId!: number;
  scanId!: number;
  value!: string;
  score!: number | null;
  deletedAt!: number | null;

  question!: Question<T>;
}
