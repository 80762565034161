import { Company } from "../../../../models/company/company";
import { PostalCode } from "../../../../models/postal-code/postalCode";

export interface StepOneState {
  kvkNumber: string;

  branch: string;
  branchError?: string;

  postalCode: PostalCode | null;
  postalCodeError?: string;

  existingCompany: Company | null;
}

export const intialStepOneState: StepOneState = {
  kvkNumber: "",
  branch: "",
  postalCode: null,
  existingCompany: null
}

export const validBranches = [
  "Advanced/Additive Manufacturing",
  "Aerospace",
  "Agrifood tech",
  "Automotive en mobiliteit",
  "Chemie en Pharma",
  "Creatieve industrie en design",
  "Energie tech",
  "Engineering",
  "Health & Life sciences",
  "HR & Recruitment",
  "ICT & Software",
  "Kennisinstelling",
  "Logistiek en transport",
  "Metaalindustrie",
  "Productie",
  "Robotics",
  "Semiconductor",
  "Water",
];

export function validateStepOneState(stepOneState: StepOneState): StepOneState {
  stepOneState.postalCodeError = !stepOneState.existingCompany && stepOneState.postalCode === null ? 'Postcode is verplicht.' : undefined;

  if (!stepOneState.existingCompany && stepOneState.branch.length === 0) {
    stepOneState.branchError = 'Branch is verplicht.';
  } else if (!stepOneState.existingCompany && validBranches.indexOf(stepOneState.branch) === -1) {
    stepOneState.branchError = 'Branch moet in de IT & maak industrie liggen';
  } else {
    stepOneState.branchError = undefined;
  }

  return stepOneState;
}

export function isStepOneStateValid(stepOneState: StepOneState): boolean {
  return !stepOneState.branchError && !stepOneState.postalCodeError;
};