import React, { useState, useEffect } from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface QuestionnaireCopyDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: number) => void;
}

export function QuestionnaireCopyDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireCopyDialogComponentProps) {
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [newQuestionnaireNameState, setNewQuestionnaireNameState] = useState<
    string
  >("");

  const handleCopy = async (questionnaire?: Questionnaire) => {
    if (!questionnaire || !questionnaire.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesCopy(questionnaire.id, {
        archiveSource: false,
        newQuestionnaireName: newQuestionnaireNameState,
      });

      handleClose(response.data.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open && questionnaire) {
      setNewQuestionnaireNameState(questionnaire.name);
    }
  }, [open, questionnaire]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          `Kopieer een bestaande vragenlijst`
        )}
      </DialogTitle>
      <DialogContent>
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <>
            <p>
              U kunt een nieuwe kopie aanmaken van de vragenlijst{" "}
              <i>{questionnaire.name}</i>. De gekopieerde vragenlijst is een
              geheel nieuwe vragenlijst en kan ook apart gepubliceerd worden.
            </p>
            <p>
              Hieronder kunt u eventueel nog de naam van de vragenlijst
              aanpassen.
            </p>
          </>
        )}
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Vragenlijst naam"
            type="text"
            value={newQuestionnaireNameState}
            onChange={(event) =>
              setNewQuestionnaireNameState(event.target.value)
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleCopy(questionnaire)} color="primary">
            Kopieer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
