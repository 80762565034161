import React from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Step } from "../../../../../../../models/step/step";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface StepDeleteDialogComponentProps {
  open: boolean;
  step?: Step;
  handleClose: (result?: number) => void;
}

export function StepDeleteDialogComponent({
  open,
  step,
  handleClose,
}: StepDeleteDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (step?: Step) => {
    if (!step || !step.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.stepsDelete(step.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(step.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!step ? <Skeleton variant="text" /> : `Verwijder ${step.name}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!step ? (
            <Skeleton variant="rect" />
          ) : (
            `Voor het verwijderen van de stap "${step.name}" moet de
            naam herhaald worden.`
          )}
        </DialogContentText>
        {!step ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Herhaal de stap naam"
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!step ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!step ? (
          <Skeleton variant="rect" />
        ) : (
          <Button
            disabled={!step || step.name !== nameState}
            onClick={() => handleDelete(step)}
            color="primary"
          >
            Verwijder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
