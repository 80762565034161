import React from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useApi } from "../../../../../../common/providers/Api.provider";

export interface QuestionnaireDeleteDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: number) => void;
}

export function QuestionnaireDeleteDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireDeleteDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (questionnaire?: Questionnaire) => {
    if (
      !questionnaire ||
      !questionnaire.id ||
      questionnaire.name !== nameState
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesDelete(questionnaire.id);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(questionnaire.id);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          `Verwijder ${questionnaire.name}`
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!questionnaire ? (
            <Skeleton variant="rect" />
          ) : (
            `Voor het verwijderen van de vragenlijst "${questionnaire.name}" moet de
            naam herhaald worden.`
          )}
        </DialogContentText>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label="Herhaal de vragenlijst naam"
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rect" />
        ) : (
          <Button
            disabled={!questionnaire || questionnaire.name !== nameState}
            onClick={() => handleDelete(questionnaire)}
            color="primary"
          >
            Verwijder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
