import React from "react";
import "./StepThreePage.component.sass";
import { Card, CardContent, Typography, TextField } from "@material-ui/core";
import { StepThreeState, validateStepThreeState } from "./StepThreePageState";

export interface StepThreePageComponentProps {
  setStepThreeState: React.Dispatch<React.SetStateAction<StepThreeState>>;

  showValidationState: boolean;

  firstName: string;
  firstNameError?: string;

  lastName: string;
  lastNameError?: string;
  
  email: string;
  emailError?: string;
  
  password: string;
  passwordError?: string;

  repeatPassword: string;
  repeatPasswordError?: string;
}

export function StepThreePageComponent({
  setStepThreeState,
  showValidationState,
  firstName,
  firstNameError,
  lastName,
  lastNameError,
  email,
  emailError,
  password,
  passwordError,
  repeatPassword,
  repeatPasswordError,
}: StepThreePageComponentProps) {
  return (
    <div className={"step-three-container"}>
      <Card className={"card"}>
        <CardContent>
          <Typography variant="h1">
            Stap 3. Aanvullen van gebruikersinformatie
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              required
              fullWidth
              label="Voornaam"
              variant="outlined"
              className={"form-control"}
              type="text"
              value={firstName}
              onChange={(event) => {
                event.persist();
                setStepThreeState(prevState => validateStepThreeState({
                  ...prevState,
                  firstName: event.target.value,
                }));
              }}
              error={!!firstNameError && showValidationState}
              helperText={!!firstNameError && showValidationState ? firstNameError : undefined}
            />
            <TextField
              required
              fullWidth
              label="Achternaam"
              variant="outlined"
              className={"form-control"}
              type="text"
              value={lastName}
              onChange={(event) => {
                event.persist();
                setStepThreeState(prevState => validateStepThreeState({
                  ...prevState,
                  lastName: event.target.value,
                }));
              }}
              error={!!lastNameError && showValidationState}
              helperText={!!lastNameError && showValidationState ? lastNameError : undefined}
            />
            <TextField
              required
              fullWidth
              label="Email"
              variant="outlined"
              className={"form-control"}
              type="email"
              value={email}
              onChange={(event) => {
                event.persist();
                setStepThreeState(prevState => validateStepThreeState({
                  ...prevState,
                  email: event.target.value,
                }));
              }}
              error={!!emailError && showValidationState}
              helperText={!!emailError && showValidationState ? emailError : undefined}
            />
            <TextField
              required
              fullWidth
              label="Wachtwoord"
              variant="outlined"
              className={"form-control"}
              type="password"
              value={password}
              onChange={(event) => {
                event.persist();
                setStepThreeState(prevState => validateStepThreeState({
                  ...prevState,
                  password: event.target.value,
                }));
              }}
              error={!!passwordError && showValidationState}
              helperText={!!passwordError && showValidationState ? passwordError : undefined}
            />
            <TextField
              required
              fullWidth
              label="Herhaal wachtwoord"
              variant="outlined"
              className={"form-control"}
              type="password"
              value={repeatPassword}
              onChange={(event) => {
                event.persist();
                setStepThreeState(prevState => validateStepThreeState({
                  ...prevState,
                  repeatPassword: event.target.value,
                }));
              }}
              error={!!repeatPasswordError && showValidationState}
              helperText={!!repeatPasswordError && showValidationState ? repeatPasswordError : undefined}
            />
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
