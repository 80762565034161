import React from "react";
import { Category } from "../../../../../../models/category/category";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export interface CategoryComponentProps {
  category: Category;
  setCategoryToUpdate: React.Dispatch<
    React.SetStateAction<Category | undefined>
  >;
  setCategoryIdToDelete: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

export function CategoryComponent({
  category,
  setCategoryToUpdate,
  setCategoryIdToDelete,
}: CategoryComponentProps) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {category.id}
      </TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>{category.scale}</TableCell>
      <TableCell>{category.showInResult ? "Ja" : "Nee"}</TableCell>
      <TableCell>
        <Tooltip title="Bewerken" placement="top">
          <IconButton onClick={() => setCategoryToUpdate(category)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Verwijderen" placement="top">
          <IconButton onClick={() => setCategoryIdToDelete(category.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
