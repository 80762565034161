import React from "react";
import ReactDOM from "react-dom";
import "./assets/global_styling.css";
import RootComponent from "./components/Root.component";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { UserProvider } from "./components/common/providers/User.provider";
import { ApiProvider } from "./components/common/providers/Api.provider";

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <ApiProvider>
      <UserProvider>
        <RootComponent />
      </UserProvider>
    </ApiProvider>
  </SnackbarProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
