import React from "react";
import { ScanListComponent } from "../scan-list/ScanList.component";

export function ScanListPageComponent() {
  return (
    <div className={"scan-list-page-container page"}>
      <ScanListComponent />
    </div>
  );
}
