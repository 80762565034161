import { testEmail } from "../../../../lib/testEmail";
import { testPhoneNumber } from "../../../../lib/testPhoneNumber";

export interface StepTwoState {
  kvkNumber: string;
  kvkNumberError?: string;

  name: string;
  nameError?: string;

  phoneNumber: string;
  phoneNumberError?: string;

  email: string;
  emailError?: string;

  postalCode: string;
  city: string;
  branch: string;
}

export const initialStepTwoState: StepTwoState = {
    kvkNumber: "",
    name: "",
    phoneNumber: "",
    email: "",
    postalCode: "",
    city: "",
    branch: ""
};

export function validateStepTwoState(stepTwoState: StepTwoState): StepTwoState {
  stepTwoState.kvkNumberError = stepTwoState.kvkNumber.length === 0 ? 'Kvk Nummer is verplicht.' : undefined;
  stepTwoState.nameError = stepTwoState.name.length === 0 ? 'Naam is verplicht.' : undefined;

  if (stepTwoState.email.length === 0) {
    stepTwoState.emailError = 'Email is verplicht.';
  } else if (stepTwoState.email.length > 0 && !testEmail(stepTwoState.email)) {
    stepTwoState.emailError = 'Geen geldig emailadres.';
  } else {
    stepTwoState.emailError = undefined;
  }

  if (stepTwoState.phoneNumber.length === 0) {
    stepTwoState.phoneNumberError = 'Telefoonnummer is verplicht.';
  } else if (stepTwoState.phoneNumber.length > 0 && !testPhoneNumber(stepTwoState.phoneNumber)) {
    stepTwoState.phoneNumberError = 'Geen geldig telefoonnummer.';
  } else {
    stepTwoState.phoneNumberError = undefined;
  }
  
  return stepTwoState;
}

export function isStepTwoStateValid(stepTwoState: StepTwoState): boolean {
  return !stepTwoState.kvkNumberError && !stepTwoState.nameError && !stepTwoState.emailError && !stepTwoState.phoneNumberError;
}