import React, { useEffect, useState } from "react";
import "./UserList.component.sass";
import { User } from "../../../../../models/user/user";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
  Button,
} from "@material-ui/core";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import PeopleIcon from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";
import { UserListItemComponent } from "../user-list-item/UserListItem.component";
import { UserAddDialogComponent } from "../dialog/user-add-dialog/UserAddDialog.component";
import { UserUpdateDialogComponent } from "../dialog/user-update-dialog/UserUpdateDialog.component";
import { UserDeleteDialogComponent } from "../dialog/user-delete-dialog/UserDeleteDialog.component";
import { UserChangePasswordDialogComponent } from "../dialog/user-change-password-dialog/UserChangePasswordDialog.component";
import { useApi } from "../../../../common/providers/Api.provider";

export function UserListComponent() {
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [hasErrorState, setHasError] = useState<boolean>(false);
  const [usersState, setUsersState] = useState<User[]>([]);

  const [isUserAddDialogOpenState, setIsUserAddDialogOpenState] = useState<
    boolean
  >(false);

  const [userToUpdateState, setUserToUpdateState] = useState<User | undefined>(
    undefined
  );

  const [userToDeleteState, setUserToDeleteState] = useState<User | undefined>(
    undefined
  );

  const [userToChangePasswordState, setUserToChangePasswordState] = useState<
    User | undefined
  >(undefined);

  const getUsers = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.users();

      setUsersState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  const handleCloseUserAddDialog = (result?: User) => {
    if (!!result) {
      setUsersState([...usersState, result]);
    }

    setIsUserAddDialogOpenState(false);
  };

  const handleCloseUserUpdateDialog = (result?: User) => {
    if (!!result) {
      setUsersState(
        usersState.map((u) => (u.id === result.id ? { ...u, ...result } : u))
      );
    }

    setUserToUpdateState(undefined);
  };

  const handleCloseUserDeleteDialog = (result?: number) => {
    if (!!result) {
      setUsersState(usersState.filter((u) => u.id !== result));
    }

    setUserToDeleteState(undefined);
  };

  const handleCloseUserChangePasswordDialog = () => {
    setUserToChangePasswordState(undefined);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <UserAddDialogComponent
        open={isUserAddDialogOpenState}
        handleClose={handleCloseUserAddDialog}
      />
      <UserUpdateDialogComponent
        open={!!userToUpdateState}
        user={userToUpdateState}
        handleClose={handleCloseUserUpdateDialog}
      />
      <UserDeleteDialogComponent
        open={!!userToDeleteState}
        user={userToDeleteState}
        handleClose={handleCloseUserDeleteDialog}
      />
      <UserChangePasswordDialogComponent
        open={!!userToChangePasswordState}
        user={userToChangePasswordState}
        handleClose={handleCloseUserChangePasswordDialog}
      />
      <div className={"user-list-container"}>
        <div className={"user-list-container__header"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <PeopleIcon />
              Gebruikers
            </Link>
          </Breadcrumbs>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsUserAddDialogOpenState(true)}
          >
            <AddIcon />
            Nieuwe gebruiker toevoegen
          </Button>
        </div>
        <TableContainer
          className={"user-list-container__table"}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Bedrijfsnaam</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Acties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersState.map((row, index) => (
                <UserListItemComponent
                  key={index}
                  user={row}
                  setUserToUpdate={setUserToUpdateState}
                  setUserToDelete={setUserToDeleteState}
                  setUserToChangePassword={setUserToChangePasswordState}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
