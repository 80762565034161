import React from "react";
import { UserListComponent } from "../user-list/UserList.component";

export function UserListPageComponent() {
  return (
    <div className={"user-list-page-container page"}>
      <UserListComponent />
    </div>
  );
}
