import React, { useState } from "react";
import { Scan } from "../../../../../../models/scan/scan";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../common/providers/Api.provider";

export interface ScanDeleteDialogComponentProps {
  open: boolean;
  scan?: Scan;
  handleClose: (result?: number) => void;
}

export function ScanDeleteDialogComponent({
  open,
  scan,
  handleClose,
}: ScanDeleteDialogComponentProps) {
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleDelete = async (scan?: Scan) => {
    if (!scan || !scan.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.scansDelete(scan.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(scan.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as string,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!scan ? <Skeleton variant="text" /> : `Verwijder scan`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!scan ? (
            <Skeleton variant="rect" />
          ) : (
            `Let op! Als een onafgemaakte scan wordt verwijderd kan deze niet terug worden gehaald.`
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!scan ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            Annuleer
          </Button>
        )}
        {!scan ? (
          <Skeleton variant="rect" />
        ) : (
          <Button onClick={() => handleDelete(scan)} color="primary">
            Verwijder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
