import { UserRole } from "../models/user/userRole";

export function convertUserRoleToText(userRole: UserRole) {
  switch (userRole) {
    case UserRole.Admin:
      return "Admin";
    case UserRole.Researcher:
      return "Onderzoeker";
    case UserRole.User:
      return "Gebruiker";
    default:
      throw Error("User role not found");
  }
}
