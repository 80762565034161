import React from "react";
import { IconButton, TableRow, TableCell, Tooltip } from "@material-ui/core";
import "./QuestionnaireListItem.component.sass";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import LayersIcon from '@material-ui/icons/Layers';
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";

export interface QuestionnaireListItemComponentProps {
  questionnaire: Questionnaire;
  setQuestionnaireToDelete: React.Dispatch<
    React.SetStateAction<Questionnaire | undefined>
  >;
  setQuestionnaireToCopy: React.Dispatch<
    React.SetStateAction<Questionnaire | undefined>
  >;
  setQuestionnaireToNewRevision: (questionnaireId: number) => void;
  navigateToQuestionnaire: (questionnaireId: number) => void;
  togglePublished: (questionnaireId: number, published: boolean) => void;
}

export function QuestionnaireListItemComponent({
  questionnaire,
  setQuestionnaireToDelete,
  setQuestionnaireToCopy,
  setQuestionnaireToNewRevision,
  navigateToQuestionnaire,
  togglePublished,
}: QuestionnaireListItemComponentProps) {
  const snackbar = useSnackbar();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {questionnaire.id}
      </TableCell>
      <TableCell>{questionnaire.name}</TableCell>
      <TableCell>{questionnaire.revision}</TableCell>
      <TableCell>
        <Tooltip
          title={
            questionnaire.published ? "Publiceren ongedaan maken" : "Publiceren"
          }
          placement="top"
        >
          <IconButton
            onClick={() =>
              togglePublished(questionnaire.id, !questionnaire.published)
            }
          >
            {questionnaire.published ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Kopieer vragenlijst" placement="top">
          <IconButton onClick={() => setQuestionnaireToCopy(questionnaire)}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        {!questionnaire.published && (
          <Tooltip title="Maak een nieuwe revisie voor de vragenlijst" placement="top">
            <IconButton onClick={() => setQuestionnaireToNewRevision(questionnaire.id)}>
              <LayersIcon />
            </IconButton>
          </Tooltip>
        )}
        {!questionnaire.published && (
          <Tooltip title="Bewerken" placement="top">
            <IconButton
              onClick={() => navigateToQuestionnaire(questionnaire.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {!questionnaire.published && (
          <Tooltip title="Verwijderen" placement="top">
            <IconButton onClick={() => setQuestionnaireToDelete(questionnaire)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {questionnaire.published == true && (
          <Tooltip title="Kopieer publieke link" placement="top">
            <IconButton onClick={async () => {
              await navigator.clipboard.writeText(`${window.location.host}/exchange/${questionnaire.publicCode}`);
              snackbar.enqueueSnackbar('Publieke link gekopieerd om te delen', getSnackbarOptions({ variant: "success" }));
            }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
