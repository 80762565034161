import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Error } from "../common/error/Error.component";
import "./RegisterComplete.component.sass";

export function RegisterCompleteComponent() {
  const history = useHistory();

  const location = useLocation();
  const state = location.state as any;

  const [counterState, setCounterState] = useState(10); 

  useEffect(() => {
    if (state && state.email) {
      setInterval(() => {
        setCounterState(prevState => prevState - 1);
      }, 1000);  
    }

    return () => {
      clearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (counterState === 0) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterState]);

  if (!state || !state.email) {
    return (
      <Error />
    );
  }

  return (
    <div className={"register-complete-container"}>
      <div className={"register-complete-container__content"}>
        <Card className={"card"}>
          <CardContent>
            <Typography variant="h1">
              Registratie voltooid!
            </Typography>
            <Typography>
              Er wordt binnen enkele minuten een email verstuurd naar <span className={"highlight"}>{state.email}</span>.
            </Typography>
            <Typography>
              In de email vind je een link waarmee je, jouw account activeerd.
            </Typography>
            <Typography>
              Je wordt over <span className={"highlight"}>{counterState}</span> seconden doorverwezen naar de inlog pagina.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
