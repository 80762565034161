import React from "react";
import { TextField } from "@material-ui/core";
import { PolarData } from "../../../../../../../../models/question/polarData";
import { Question } from "../../../../../../../../models/question/question";

export interface PolarInputComponentProps {
  value: Question<PolarData>;
  setValue: (value: Question<PolarData>) => void;
}

export function PolarInputComponent({
  value,
  setValue,
}: PolarInputComponentProps) {
  return (
    <div className="polar-input">
      <TextField
        fullWidth
        required
        variant="outlined"
        label="Score"
        color="primary"
        type="number"
        className={"form-control"}
        value={value.data.score || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({ ...value, data: { score: Number(event.target.value) } })
        }
      />
    </div>
  );
}
