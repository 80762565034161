import { Scan } from "../models/scan/scan";
import { RadarGraphEntry } from "../components/scan/graph/radar-graph/radarGraphEntry";
import { Mode } from "./mode";
import { convertModeToText } from "./convertModeToText";

export function formatScanToRadarGraphData(
  scan: Scan,
  mode: Mode
): RadarGraphEntry[] {
  const entries: RadarGraphEntry[] = [];

  if (!!scan) {
    for (const categoryResult of scan.scanCategoryResults) {
      const category = categoryResult.category;

      entries.push({
        category: category.name,
        [convertModeToText(mode)]: categoryResult.scaledScore,
      } as any);
    }
  }

  return entries;
}
