import React, { useState, ChangeEvent, useEffect } from "react";
import { Typography, TextField, Card, CardContent, MenuItem, IconButton, Chip, InputAdornment, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useApi } from "../../../common/providers/Api.provider";
import { PostalCode } from "../../../../models/postal-code/postalCode";
import { Company } from "../../../../models/company/company";
import { Loader } from "../../../common/loader/Loader.component";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Search } from "@material-ui/icons";
import { StepOneState, validateStepOneState } from "./StepOnePageState";
import "./StepOnePage.component.sass";

export interface StepOnePageComponentProps {
  setStepOneState: React.Dispatch<React.SetStateAction<StepOneState>>;

  showValidationState: boolean;

  kvkNumber: string;

  branch: string;
  branchError?: string;

  postalCode: PostalCode | null;
  postalCodeError?: string;

  existingCompany: Company | null;
}

export function StepOnePageComponent({
  setStepOneState,
  showValidationState,
  kvkNumber,
  branch,
  branchError,
  postalCode,
  postalCodeError,
  existingCompany
}: StepOnePageComponentProps) {
  const api = useApi();

  const [hasSearchBeenFiredState, setHasSearchBeenFiredState] = useState<boolean>(false);
  const [isExistingCompanyLoadingState, setIsExistingCompanyLoadingState] = useState<boolean>(false);
  const [foundCompanyState, setFoundCompanyState] = useState<Company | null>(null);

  const [arePostalCodesLoadingState, setArePostalCodesLoadingState] = useState<boolean>(false);
  const [postalCodesState, setPostalCodesState] = useState<PostalCode[]>([]);

  const currentlySelectedEqualsFoundCompany = () => {
    return (
      foundCompanyState == null ||
      (!!foundCompanyState && foundCompanyState.kvkNumber) !==
      (!!existingCompany && existingCompany.kvkNumber)
    );
  };

  const getPostalCodes = async () => {
    try {
      setArePostalCodesLoadingState(true);

      const response = await api.postalCodes();
      setPostalCodesState(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setArePostalCodesLoadingState(false);
    }
  };

  const getExistingCompany = async (kvkNumber: string) => {
    try {
      setHasSearchBeenFiredState(true);
      setIsExistingCompanyLoadingState(true);

      const { data: foundCompany } = await api.findCompanyByKvkNumber(kvkNumber);

      setFoundCompanyState(foundCompany ?? null);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExistingCompanyLoadingState(false);
    }
  };

  useEffect(() => {
    getPostalCodes();
    setHasSearchBeenFiredState(!!existingCompany);
    setFoundCompanyState(!!existingCompany ? existingCompany : null)
    // eslint-disable-next-line
  }, []);

  return (
    <div className={"step-one-container"}>
      <Card className={"card"}>
        <CardContent>
          <Typography variant="h1">Stap 1. Bedrijfscheck</Typography>
          <Typography>
            Om te waarborgen dat een werkgever zich maar eenmalig kan aanmelden, dien je een kvk-nummer in te vullen.
          </Typography>
          <Typography>
            Als er geen organisatie gevonden wordt kun je deze op basis van de volgende criteria registreren:
          </Typography>
          <ul>
            <li>De werkgever is in Noord-Brabant gevestigd;</li>
            <li>De werkgever is actief binnen de High Tech Maakindustrie en/of IT.</li>
          </ul>
        </CardContent>
      </Card>
      <form noValidate autoComplete="off">
        <div className={"existing-company"}>
          <TextField
            fullWidth
            variant="outlined"
            label="Kvk nummer"
            placeholder="Vul een kvk nummer in..."
            value={kvkNumber}
            className={"form-control search-company"}
            onChange={(event) => {
              event.persist();
              setStepOneState(prevState => validateStepOneState({
                ...prevState,
                kvkNumber: event.target.value
              }));
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();

                getExistingCompany(kvkNumber);
              }
            }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Zoek naar een bedrijf"
                    onClick={() => {
                      getExistingCompany(kvkNumber)
                    }}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>

            }}
          />
          {isExistingCompanyLoadingState && <Loader loadingText={"Bedrijf aan het zoeken..."} />}
          {!isExistingCompanyLoadingState && !hasSearchBeenFiredState && (
            <div className={"text"}>
              <Typography>Er is nog geen zoekopdracht gedaan.</Typography>
            </div>
          )}
          {!isExistingCompanyLoadingState && hasSearchBeenFiredState && !foundCompanyState && (
            <div className={"text"}>
              <Typography>Er is geen bedrijf gevonden.</Typography>
            </div>

          )}
          {!isExistingCompanyLoadingState && foundCompanyState && (
            <Card className={"company"}>
              <div className={"id"}>
                <Chip
                  color="secondary"
                  label={`KvK Nr. ${foundCompanyState.kvkNumber}`}
                />
              </div>
              <div className={"info"}>
                {foundCompanyState.name}
              </div>
              <div className={"action"}>
                {currentlySelectedEqualsFoundCompany() && (
                  <Tooltip title="Selecteer" placement="top">
                    <IconButton
                      component="div"
                      className={"icon-button check"}
                      onClick={() => {
                        setStepOneState(prevState => validateStepOneState({
                          ...prevState,
                          existingCompany: foundCompanyState,
                        }));
                      }}
                    >
                      <DoneIcon className={"icon"} />
                    </IconButton>
                  </Tooltip>
            
                )}
                {!currentlySelectedEqualsFoundCompany() && (
                  <Tooltip title="Verwijder selectie" placement="top">
                    <IconButton
                      component="div"
                      className={"icon-button remove"}
                      onClick={() => {
                        setStepOneState(prevState => validateStepOneState({
                          ...prevState,
                          existingCompany: null
                        }));
                      }}
                    >
                      <CloseIcon className={"icon"} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Card>
          )}
        </div>

        {!existingCompany &&
          <div className="no-selected-company">
            <Autocomplete
              className={"form-control"}
              onChange={(event: ChangeEvent<{}>, values: PostalCode | null) => {
                event.persist();
                setStepOneState(prevState => validateStepOneState({
                  ...prevState,
                  postalCode: values
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Postcode"
                  error={!!postalCodeError && showValidationState}
                  placeholder="Postcode zoeken..."
                  helperText={!!postalCodeError && showValidationState ? postalCodeError : undefined}
                />
              )}
              getOptionLabel={(postalCode) => `${postalCode.code}, ${postalCode.municipality}, ${postalCode.place}`}
              options={!arePostalCodesLoadingState ? postalCodesState : []}
              disabled={!!existingCompany}
            />
            <TextField
              className={"form-control"}
              select
              fullWidth
              variant="outlined"
              label="Branch"
              value={branch}
              error={!!branchError && showValidationState}
              helperText={!!branchError && showValidationState ? branchError : undefined}
              disabled={!!existingCompany}
              onChange={(event) => {
                event.persist();
                if (!!event.target.value) {
                  setStepOneState(prevState => validateStepOneState({
                    ...prevState,
                    branch: event.target.value
                  }));
                }
              }}>
              <MenuItem value="Advanced/Additive Manufacturing">Advanced/Additive Manufacturing</MenuItem>
              <MenuItem value="Aerospace">Aerospace</MenuItem>
              <MenuItem value="Agrifood tech">Agrifood tech</MenuItem>
              <MenuItem value="Automotive en mobiliteit">Automotive en mobiliteit</MenuItem>
              <MenuItem value="Chemie en Pharma">Chemie en Pharma</MenuItem>
              <MenuItem value="Creatieve industrie en design">Creatieve industrie en design</MenuItem>
              <MenuItem value="Energie tech">Energie tech</MenuItem>
              <MenuItem value="Engineering">Engineering</MenuItem>
              <MenuItem value="Health & Life sciences">Health & Life sciences</MenuItem>
              <MenuItem value="HR & Recruitment">HR & Recruitment</MenuItem>
              <MenuItem value="ICT & Software">ICT & Software</MenuItem>
              <MenuItem value="Kennisinstelling">Kennisinstelling</MenuItem>
              <MenuItem value="Logistiek en transport">Logistiek en transport</MenuItem>
              <MenuItem value="Metaalindustrie">Metaalindustrie</MenuItem>
              <MenuItem value="Productie">Productie</MenuItem>
              <MenuItem value="Robotics">Robotics</MenuItem>
              <MenuItem value="Semiconductor">Semiconductor</MenuItem>
              <MenuItem value="Water">Water</MenuItem>
            </TextField>
          </div>
        }
      </form>
    </div>
  );
}
